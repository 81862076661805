import React, { useState, useEffect } from 'react';
import './AttributeList.css';

const AttributeList = ({ attributes, header = true, attachedCreation }) => {
  const [isOpen, setIsOpen] = useState(true);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const attributeMapping = {
    baseTheme: 'Genre',
    origin: 'Background',
    skillsAndAbilities: 'Skills',
    additionalDetails: 'Details',
    physicalAppearance: 'Appearance'
  };

  const getAttributeName = (originalName) => {
    return attributeMapping[originalName] || originalName;
  };

  const getHrefForLocation = (field) => {
    const locationValue = attributes[field];

    if (Array.isArray(attachedCreation) && attachedCreation.length > 0) {
      for (let attached of attachedCreation) {
        if (
          attached?.formValues?.["Name"] === locationValue ||
          attached?._id === locationValue
        ) {
          return `/characters?id=${attached._id}`;
        }
      }
    }
    return null;
  };

  const getImageForLocation = (field) => {
    const locationValue = attributes[field];
    if (Array.isArray(attachedCreation) && attachedCreation.length > 0) {
      for (let attached of attachedCreation) {
        if (
          attached?.formValues?.["Name"] === locationValue ||
          attached?._id === locationValue
        ) {
          return getBaseURL() + `${attached.imageUrl}`;
        }
      }
    }
    return null;
  };

  const getDisplayValue = (field, value) => {
    if (Array.isArray(attachedCreation) && attachedCreation.length > 0) {
      for (let attached of attachedCreation) {
        if (attached?._id === value) {
          return attached.formValues?.['Name'] || value;
        }
      }
    }
    return value;
  };

  function getBaseURL() {
    var url = window.location.href;
    var apiUrl = 'http://localhost:3001/saved/images/';
    if (url.indexOf('charactercomposer.com') > -1)
      apiUrl = 'https://charactercomposer.com/saved/images/';
    return apiUrl;
  }

  useEffect(() => {
    console.log('attachedCreation attribute list');
    console.log(attachedCreation);
  }, [attachedCreation]);

  return (
    <div className="attribute-list">
      {header && (
        <div className="accordion-header" onClick={handleClick}>
          <h3>Attributes</h3>
          <span>{isOpen ? '-' : '+'}</span>
        </div>
      )}
      {isOpen && (
        <div className="accordion-content">
          <div className="column">
            {Object.entries(attributes)
              .filter(([key]) => key !== 'name' && key !== 'Name' && key !== 'visualTheme')
              .slice(0, Object.entries(attributes).length / 2)
              .map(([key, value]) => (
                <div key={key} className="attribute">
                  <span className="attribute-name">{getAttributeName(key)}:</span>
                  <span className="attribute-value">
                    {key.includes(' (user generated)') &&
                    getHrefForLocation(key) ? (
                      <a href={getHrefForLocation(key)}>
                        {getDisplayValue(key, value)}
                        {getImageForLocation(key) && (
                          <img
                            src={getImageForLocation(key)}
                            alt={getDisplayValue(key, value)}
                            style={{ maxWidth: '100px', marginLeft: '10px' }}
                            loading="lazy"
                          />
                        )}
                      </a>
                    ) : (
                      getDisplayValue(key, value)
                    )}
                  </span>
                </div>
              ))}
          </div>
          <div className="column">
            {Object.entries(attributes)
              .filter(([key]) => key !== 'name')
              .slice(Object.entries(attributes).length / 2)
              .map(([key, value]) => (
                <div key={key} className="attribute">
                  <span className="attribute-name">{getAttributeName(key)}:</span>
                   <span className="attribute-value">
                    {key.includes(' (user generated)') &&
                    getHrefForLocation(key) ? (
                      <a
                        href={getHrefForLocation(key)}
                        rel="noopener noreferrer"
                      >
                        <div className="attributesLinkedCreation">
                          <div>{getDisplayValue(key, value)}</div>
                          <div>
                            {getImageForLocation(key) && (
                              <img
                                src={getImageForLocation(key)}
                                alt={getDisplayValue(key, value)}
                                loading="lazy"
                              />
                            )}
                          </div>
                        </div>
                      </a>
                    ) : (
                      getDisplayValue(key, value)
                    )}
                  </span>
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default AttributeList;
