const SaveSuccessModal = ({ showSaveSuccessModal, toggleSaveSuccessModal, errorMessage }) => {
  if (!showSaveSuccessModal && !errorMessage) return null;

  const handleOutsideClick = (event) => {
    if (!errorMessage) {
      toggleSaveSuccessModal();
    }
  };

  const handleInsideClick = (event) => {
    event.stopPropagation();
  };

  
  return (
    <div className="save-success-modal" onClick={handleOutsideClick}>
      <div className="modal-content" onClick={handleInsideClick}>
        {errorMessage ? (
          <>
            <h2>You have run out of save slots</h2>
            <p>{errorMessage}</p>
            <p>Manage your characters from your <a target="_blank" href="/dashboard/characters/">dashboard</a>.</p>
          </>
        ) : (
          <>
            <h2>Character Saved</h2>
            <p>View all your characters from your <a href="/dashboard/characters/">dashboard</a>.</p>
          </>
        )}
        <button onClick={toggleSaveSuccessModal}>Return</button>
      </div>
    </div>
  );
};

export default SaveSuccessModal;
