import React from 'react';

var TryAgain = () => {
    return (
      <div className="container">
        <div className="try-again">
          <br/>
          <br/>
          
        </div>
      </div>
    );
  }
export default TryAgain;


