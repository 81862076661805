import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FaDice, FaBars, FaTimes, FaPlus, FaStar } from 'react-icons/fa';
import LoginModal from '../Composer/LoginModal.js';
import UserTokens from '../Composer/UserTokens';
import Sales from './Sales.js';

const Header = ({ showLoginModal, toggleLoginModal, genericMessage, isPlusMember, upsellVisible, toggleUpsellVisible }) => {
  const location = useLocation();
  const [isSingleView, setIsSingleView] = useState(false);
  const [sideNavVisible, setSideNavVisible] = useState(false);

  const [salesModalVisible, setSalesModalVisible] = useState(upsellVisible);

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isPlus, setIsPlus] = useState(isPlusMember);

  const stripeCustomerId = localStorage.getItem('stripeCustomerId');

  const username = localStorage.getItem('username');

  useEffect(() => {
    setTimeout(function(){setSalesModalVisible(upsellVisible);},0)
    
  }, [upsellVisible]);
  useEffect(() => {

  }, [showLoginModal]);
  useEffect(() => {
    setInterval(function(){
      const token = localStorage.getItem('token');
      if (token) {
        setIsLoggedIn(true);
      }
      const plus = localStorage.getItem('isPlusMember');
      if (plus == 'true') {
        setIsPlus(true);
      }
    },1000);
  }, []);

  useEffect(() => {
    const url = new URL(window.location.href);
    const id = url.searchParams.get("id");
    if (id) setIsSingleView(true);
  }, [location.pathname]);

  const toggleSideNav = () => setSideNavVisible(!sideNavVisible);
  const toggleSalesModal = () => setSalesModalVisible(!salesModalVisible);
  const isRootPage = location.pathname === '/';
  let isWelcomePage = false;
  if(location.pathname.indexOf('welcome') > -1){
      isWelcomePage = true;
    }

  const goBackOrHome = () => {
    const previousUrl = window.location.href;
    window.history.back();
    setTimeout(() => {
      if (window.location.href === previousUrl) {
        window.location.href = "/";
      }
    }, 100); // Timeout can be adjusted based on testing
  };

  const backButton = !isSingleView && !isWelcomePage && (
    <button className="back-button" onClick={goBackOrHome}>
      &#8592; Back
    </button>
  );
  const closeSideNav = () => setSideNavVisible(false);
  const closeSalesModal = () => {
    setSalesModalVisible(false);
    toggleUpsellVisible(false);
  }

  var url = window.location.href;
  var apiUrl = 'http://localhost:3001/create-portal-session/';
  if (url.indexOf('charactercomposer.com') > -1) {
    apiUrl = 'https://charactercomposer.com/create-portal-session/';
  }

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    localStorage.removeItem('currentCharacter');
    localStorage.removeItem('viewType');
    localStorage.removeItem('customFields');
    localStorage.removeItem('stripeCustomerId');
    localStorage.removeItem('isPlusMember');
    window.location.href = "/";
  };

  const handleLogin = () => {
    setSideNavVisible(false);
    toggleLoginModal();
  };

  const handleClickSubscribe = () => {
    setSideNavVisible(false);
    toggleSalesModal();
  }

  return (
    <>
      <div className="header">
        {sideNavVisible && <div className="side-nav-main-overlay" onClick={closeSideNav}></div>}
        {salesModalVisible && <div className="sales-modal-overlay" onClick={closeSalesModal}></div>}
        <div className="container">
          <div className="hero-banner">
            <FaBars className="hamburger" onClick={toggleSideNav} />
            <div className={`side-nav-main ${sideNavVisible ? 'visible' : ''}`}>
              {isLoggedIn && isPlus && 
                <div className="plus-badge"> 
                    <span>CC<FaPlus/></span> Member
                </div>
              }
              {username &&
                <div className="usernameBadge">
                  <b>{username}</b>
                </div>
              }
              <FaTimes className="side-nav-main-close" onClick={closeSideNav} />
              <a href="/">Compose</a>
              <a href="/chat">Chat</a>
              <a href="/characters">Browse</a>
              
              {isLoggedIn ? (
                <>
                  <a href="/characters?view=mine">My Creations</a>
                  {isPlus ? (
                    // Replace href with onClick event
                  <>
                    <form action={apiUrl} method="POST" target="_self">
                      <input
                        type="hidden"
                        id="customer-id"
                        name="customer_id"
                        value={stripeCustomerId}
                      />
                      <button id="checkout-and-portal-button-nav" type="submit">
                        Manage Subscription
                      </button>
                    </form>
                  </>
                  ):(
                    <a className="ccPlusMenuItem" href="javascript:void(0)" onClick={handleClickSubscribe} >Subscribe to<br/>Character Composer <FaPlus className="plus"/></a>
                  )}
                  <a href="/" onClick={handleLogout}>Logout</a>
                </>
              ):(<a className="ccPlusMenuItem" onClick={handleLogin} href="javascript:void(0)">Register / Login</a>)} 
            </div>
            <div className={`sales-modal ${salesModalVisible ? 'visible' : ''}`}>
              <Sales
               closeUpsellModal={closeSalesModal}/>
            </div>
            <LoginModal
              showModalFromParent={showLoginModal}
              toggleLoginModal={toggleLoginModal}
              message={genericMessage}
              toggleUpsellVisible={toggleUpsellVisible}
            />
            <a href="/" className="brand-logo">
              <FaDice className="header-icon" />
              <h1>Character Composer</h1>
            </a>
          </div>
          {!isRootPage && backButton}
        </div>
      </div>
    </>
  );
};

export default Header;
