import React, { useState } from 'react';
import { FaDiceFive } from 'react-icons/fa';

const SimpleInput = ({ label, name, placeholder, value, onChange, isSimple = false, onRandomClick = false }) => {
    const [isLoading, setIsLoading] = useState(false);
const handleGetRandom = async (event) => {
    setIsLoading(true); // Set isLoading to true before calling onRandomClick
    var new_value = await onRandomClick(event, label);
    setIsLoading(false); // Set isLoading back to false after onRandomClick completes
    console.log('new value is: ' +new_value);
    value = new_value;
    // Trigger the onChange event to notify the parent component
    const customEvent = { target: { name, value: new_value } };
    onChange(customEvent);
  };
  const handleKeyPress = (e) => {
  if (e.key === "Enter") {
    e.preventDefault();
  }
};
  return (
    <div className='form-section'>
      <label className="form-label" htmlFor={name}>
        {label}
        {onRandomClick && 
          <FaDiceFive 
              onClick={handleGetRandom}
              className="input-dice"
          />
        }
      </label>
      <input
        className={`form-input ${value ? 'filled' : ''} ${isLoading ? 'loading' : ''}`}
        type="text"
        id={name}
        placeholder={placeholder}
        value={value}
        name={name}
        onKeyPress={handleKeyPress}
        onChange={onChange}
      />
    </div>
  );
};

export default SimpleInput;
