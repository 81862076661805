import React, { useState, useEffect } from 'react';
import { FaCopy } from 'react-icons/fa';

const ImageStyleOptionSelect = ({ visualOptions, imageStyle, onUpdate, handleChangeVisualStyle, refreshImage, dalle3, handleChangeImageModel, handleCloseVisualStyleModal }) => {
  const [localData, setLocalData] = useState('');
  const [isCopied, setIsCopied] = useState(false);
  const [hasChanged, setHasChanged] = useState(false); // State to track if any input has been changed

  useEffect(() => {
    if (refreshImage) {
      const data = localStorage.getItem('currentCharacter');
      setLocalData(JSON.parse(data));
    }
  }, [refreshImage]);

  const handleCopy = () => {
    navigator.clipboard.writeText(localData.imagePrompt);
    setIsCopied(true);
    setTimeout(function(){ setIsCopied(false); }, 300);
  };

  const handleSelectChange = (event) => {
    setHasChanged(true); // Set hasChanged to true when the selection changes
    handleChangeVisualStyle(event, 'false'); // Optionally update the parent component if needed
  };

  const handleModelChange = (event) => {
    setHasChanged(true); // Set hasChanged to true when the selection changes
    handleChangeImageModel(event)
  };

  const handleSave = (event) => {
    if (hasChanged && refreshImage !== 'false'){
      handleCloseVisualStyleModal(true);
      setHasChanged(false); // Reset the hasChanged state
    }else{
    handleCloseVisualStyleModal();
  }
  };

  return (
    <div className="visualStyleSaveModal">
        <h2>Choose visual style for image</h2>
        <select className="type-selection" id="visualStyleSelect" value={imageStyle} onChange={handleSelectChange}>
            {visualOptions.map((option, index) => (
              <option value={option} key={index}>
                {option}
              </option>
            ))}
        </select>
        <div className="dalle3Toggle">
          <label>
            <input type="checkbox" checked={dalle3} onChange={(event) => handleModelChange(event)}/> 
            Use Dall-e 3
          </label>
          <p>Higher resolution and quality image generation</p>
        </div>
        
        {refreshImage == "true" && 
        <div>
        
          <p className="imagePromptText"><b>Image Prompt</b>
          <FaCopy onClick={handleCopy}  style={{ color: isCopied ? '#ECA913' : 'white' }}>
        </FaCopy><br/><span>{localData.imagePrompt}</span></p>
        </div>
        } 
        <button onClick={handleSave}>Save</button>
    </div>
  );
};


export default ImageStyleOptionSelect;
