import React from 'react';
import RecentCreations from '../Sections/RecentCreations.js';

const RPGCharacterLanding = ({}) => {
  return (
    <>
      <div className="landingpage dnd">
        <div className="container">
          <div className="row">
            <div>
              <h1>Bring Your Stories to Life with the Ultimate Character Designer</h1>
              <img src={`${process.env.PUBLIC_URL}/sales/8CkypYadlFCML2BoKBPctTbQ3D.png`}/>
              <p>Craft Complex Characters with Ease</p>
              <p>Welcome to a New Era of Character Creation</p>
              <p>Unlock your creative potential with our Character Designer tool. Perfect for writers, artists, and all kinds of storytellers, our tool is designed to inspire and refine your character creation process, taking your stories to new heights.</p>
            </div>
            <div>
              <img src={`${process.env.PUBLIC_URL}/sales/unnamed (6).png`}/>
            </div>
          </div>
          <div className="row">
            <div>
              <h2>Features</h2>
              <ul>
                <li><strong>In-Depth Character Building:</strong> Create characters with rich backstories, dynamic personalities, and complex emotions. Our Character Designer is equipped with features to delve into the psychology, history, and motivation of your characters.</li>
                <br/><li><strong>Visual and Descriptive Elements:</strong> Combine visual elements with descriptive writing. Choose physical attributes, clothing, and accessories, and get descriptive prompts to help flesh out your character's appearance and demeanor.</li>
                <br/><li><strong>Interactive Story Integration:</strong> See how your character fits into different story settings and plots. Interact with your character in various scenarios, ensuring they are well-rounded and adaptable to different narratives.</li>
              </ul>
            </div>
            <div>
              <img src={`${process.env.PUBLIC_URL}/sales/retVtnhaRv6YF8JPTOdiApH03D.png`}/>
            </div>
          </div>
          <div className="row">
            <div>
              <h2>Why Choose Our Character Designer?</h2>
              <p><strong>Tailored for Storytellers:</strong> Specifically designed for writers, game designers, and creative professionals.</p>
              <p><strong>Dynamic Character Arcs:</strong> Develop character growth and evolution throughout your story by engaging in direct interactions with your creations.</p>
              <p><strong>Collaborative Features:</strong> Share and collaborate with peers for feedback and co-creation.</p>
              <p><strong>Creative Inspiration:</strong> Access a vast range of prompts, archetypes, and inspirations to kickstart your creativity.</p>
              <br/><a href="/"><button className="form-button">Embark on Your Creative Journey Now!</button></a><br/><br/>
              <div className="describe-character-box">
                <p>Join a community of storytellers who have brought their characters to life with our tool. Click [here] to start creating!</p>
              </div>
            </div>
            <div>
              <img src={`${process.env.PUBLIC_URL}/sales/unnamed (1).png`}/> <img src={`${process.env.PUBLIC_URL}/sales/unnamed (3).png`}/>
            </div>
          </div>
          <div className="row">
            <div>
              <p>Check out the latest and greatest characters created by the community using our DnD character creator!</p>
            </div>
            <div>
              
            </div>
          </div>
          
        </div>
      </div>
      <div className="container">
        <RecentCreations mode={'recent'}/>
      </div>
    </>
  );
};

export default RPGCharacterLanding;
