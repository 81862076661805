import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const MyCarousel = ({ slides }) => {
  const calculateSlideWidth = () => {
    const containerWidth = Math.min(window.innerWidth, 1024);
    const widthBasedOnContainer = containerWidth / 4;
    return Math.min(widthBasedOnContainer, 250) + 16; 
  };

  const [slideWidth, setSlideWidth] = useState(calculateSlideWidth());

  useEffect(() => {
    const handleResize = () => {
      setSlideWidth(calculateSlideWidth());
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const getCenterSlidePercentage = () => {
    const containerWidth = Math.min(window.innerWidth, 1024);
    return (100 * slideWidth) / containerWidth;
  };

  function getModeTitle(card) {
  try {
    let parsed = JSON.parse(card.mode);
    return Object.values(parsed)[0];
  } catch (e) {
    return null;  // or some default value
  }
}

  return (
  <Carousel
    showStatus={false}
    showIndicators={false}
    showThumbs={false}
    showArrows={false}
    interval={6000}
    focusOnSelect={false}
    infiniteLoop={true}
    autoPlay={true}
    centerMode
    selectedItem={1}
    centerSlidePercentage={getCenterSlidePercentage()}
    className="slides"
  >
    {[...slides]
    .map((slide, index) => (
      <div className="slide-inner" key={index} style={{ width: slideWidth }}>
        <h3> {slide.title} </h3>
        <img src={slide.image} alt={slide.title} />
        {
          getModeTitle(slide) && <span className="modeTitle">{slide.simpleMode == "true" ? "Simple" : "Detailed"} {getModeTitle(slide)}</span>
        }
        <div className="description-container">
          {slide && slide.description && Object.entries(slide.description).map(([key, value], index) => (
            typeof value !== 'object' ? (
              <div key={index} className="character-paragraph">
                <h3>{key}</h3>
                <p>{value}</p>
              </div>
            ) : null
          ))}
        </div>
        <div className="fade-gray"></div>
      </div>
    ))}

  </Carousel>
);
};

export default MyCarousel;
