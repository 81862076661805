import React, { useState, useEffect, useRef } from 'react';
import { FaCheck,FaPlus } from 'react-icons/fa';
var Welcome = () => {
  let [message, setMessage] = useState('');
  let [success, setSuccess] = useState(false);
  let [sessionId, setSessionId] = useState('');
  let [customerId, setCustomerId] = useState(localStorage.getItem('stripeCustomerId'));
  let [welcomeMode, setWelcomeMode] = useState('plus');

  const hasCalledSuccessAPI = useRef(false); 
  useEffect(() => {
        if(localStorage.getItem('isPlusMember') == 'true')
          setWelcomeMode('plus');
        else
          setWelcomeMode('free');
        // Check to see if this is a redirect back from Checkout
        const query = new URLSearchParams(window.location.search);
        const token = localStorage.getItem('token');
        if (query.get('success') && !hasCalledSuccessAPI.current && token) {
          const sessionID = query.get('session_id');
          
          // Call the /success endpoint to save customer ID in your database
          var url = window.location.href;
          var apiUrl = 'http://localhost:3001/success';

          if (url.indexOf('charactercomposer.com') > -1) {
            apiUrl = 'https://charactercomposer.com/success';
          }
          fetch(apiUrl, {
            method: 'POST', 
            headers: {
              'Content-Type': 'application/json',
              'API-Key': "2(yY62.mG#6UI324324",
              'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({ 
              session_id: sessionID
            })
          })
            .then(response => response.json())
            .then(data => {
              if (data.success) {
                setCustomerId(data.stripeCustomerId);
                localStorage.setItem('stripeCustomerId', data.stripeCustomerId);
                setWelcomeMode('plus');
                hasCalledSuccessAPI.current = true;  // Mark the API as called
                setSuccess(true);  // Set success state to true
                setSessionId(sessionID);
              } else {
                setMessage(data.message || "Something went wrong!");
              }
            })
            .catch(error => {
              setMessage("An error occurred while processing your request.");
            });
        }
      }, []);

  var url = window.location.href;
  var apiUrl = 'http://localhost:3001/create-portal-session/';
  if (url.indexOf('charactercomposer.com') > -1) {
    apiUrl = 'https://charactercomposer.com/create-portal-session/';
  }
    return (
      <div className="container">
        <div className="welcome">
          <FaCheck className="bigGreenTick"/>
          <h2> Welcome </h2>
          <h4>You are a <span>Character Composer{welcomeMode == 'plus' && (<FaPlus/>)}</span> member!</h4>
          <p>
            Get started by composing something new or chat to an existing creation.
          </p>
          <a href="/"><button>Compose something cool</button></a><br/>
          <a href="/chat"><button>Chat with creations</button></a><br/>
          <a href="/characters"><button>Browse creations</button></a><br/>
          {welcomeMode == 'plus' && (
            <form action={apiUrl} method="POST">
              <input
                type="hidden"
                id="customer-id"           
                name="customer_id"         
                value={localStorage.getItem('stripeCustomerId')}
              />
              
              <button id="checkout-and-portal-button2" type="submit">
                Manage your billing information
              </button>
            </form>
          )}
          {welcomeMode == 'free' && (<br/>)}
          <p>
            Thankyou for joining us 🥰
          </p>
        </div>
      </div>
    );
  }
export default Welcome;


