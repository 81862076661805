import React, { useState, useEffect } from 'react';

const CheckBoxForm = ({ outputOptions, onSubmit, onUpdate, maxOutputs = 4, onCreatedCharacter }) => {
    const [checkedItems, setCheckedItems] = useState(outputOptions);
    const initiallyCheckedItems = { ...outputOptions };


    const handleChange = (event) => {
        const { name, checked } = event.target;
        const checkedCount = Object.values(checkedItems).filter(Boolean).length;

        if (!checked && initiallyCheckedItems[name] && maxOutputs > 4) return;

        if (checked && checkedCount < maxOutputs) {
            setCheckedItems({ ...checkedItems, [name]: checked });
        }
        else if (!checked && checkedCount > 1) {
            setCheckedItems({ ...checkedItems, [name]: checked });
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        //if on created character then do onUpdate
        //if on form then do on submit


        if(onCreatedCharacter) {
            onUpdate(checkedItems);
        } else {
            console.log('onsubmit');
            console.log(checkedItems);
            onSubmit(checkedItems);
        }
    }

    const checkedCount = Object.values(checkedItems).filter(Boolean).length;

    return (
        <form className="output-form" onSubmit={handleSubmit}>
            <p>Choose up to 4 new descriptions to add at a time.</p>
            <div className="checkboxContainer">
                
                {Object.entries(checkedItems).map(([label, isChecked]) => {
                    var isDisabled = !isChecked && checkedCount >= maxOutputs;
                    if(label == 'DnD Stats')
                        isDisabled = true;
                    return (
                        <div key={label}>
                            <input 
                                type="checkbox" 
                                id={label} 
                                name={label} 
                                checked={isChecked} 
                                onChange={handleChange} 
                                disabled={isDisabled}
                            />
                            <label htmlFor={label} style={{ cursor: "pointer", userSelect: "none", opacity: isDisabled ? 0.8 : 1 }}>
                              {label}
                            </label>

                        </div>
                    );
                })}
            </div>
            <button className="submit-button" type="submit">{maxOutputs > 3 ? "Update" : "Save"}</button>
        </form>
    );
}

export default CheckBoxForm;
