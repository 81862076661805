import React, { useState, useEffect, useRef } from 'react';
import { FaCheckCircle, FaPlus } from 'react-icons/fa';

const Sales = ({ closeUpsellModal = false, handleSetPlus = false, className }) => {
  let [message, setMessage] = useState('');
  let [success, setSuccess] = useState(false);
  let [sessionId, setSessionId] = useState('');
  let [customerId, setCustomerId] = useState(localStorage.getItem('stripeCustomerId'));
  const [price, setPrice] = useState('$7.99');
  const hasCalledSuccessAPI = useRef(false);

  useEffect(() => {

    fetch('https://ipapi.co/json/')
      .then(response => response.json())
      .then(data => {
        if (data.country === 'GB') {
          setPrice('£6.99'); // Set price to UK price if user is in GB
        }
      }).catch(error => {
        console.error('Error fetching IP data', error);
      });

    const query = new URLSearchParams(window.location.search);
    const token = localStorage.getItem('token');
    if (query.get('success') && !hasCalledSuccessAPI.current && token) {
      const sessionID = query.get('session_id');
      let apiUrl = 'http://localhost:3001/success/';
      if (window.location.href.indexOf('charactercomposer.com') > -1) {
        apiUrl = 'https://charactercomposer.com/success/';
      }
      fetch(apiUrl, {
        method: 'POST', 
        headers: {
          'Content-Type': 'application/json',
          'API-Key': "2(yY62.mG#6UI324324",
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ session_id: sessionID })
      })
        .then(response => response.json())
        .then(data => {
          if (data.success) {
            setCustomerId(data.stripeCustomerId);
            localStorage.setItem('stripeCustomerId', data.stripeCustomerId);
            localStorage.setItem('isPlusMember', 'true');
            localStorage.removeItem('tokenCount');
            handleSetPlus('true');
          } else {
            setMessage(data.message || "Something went wrong!");
          }
        })
        .catch(error => {
          setMessage("An error occurred while processing your request.");
        });

      hasCalledSuccessAPI.current = true;
      setSuccess(true);
      setSessionId(sessionID);
    }
    if (query.get('canceled')) {
      setSuccess(false);
      setMessage("Order canceled, please try again");
    }
  }, []);

  var url = window.location.href;
  var apiUrl = 'http://localhost:3001/create-checkout-session/';
  if (url.indexOf('charactercomposer.com') > -1) {
    apiUrl = 'https://charactercomposer.com/create-checkout-session/';
  }
  var token = localStorage.getItem('token');
  return (
    <div className={`upsell-modal-content ${className}`}>
      {message && <div><br/><br/><p>{message}</p><a  href="/">Back to composer</a><br/><br/></div>}
      <h2>Character Composer <FaPlus/></h2>
      <p><span>Supercharge</span> your storytelling!</p>
      <ul>
        <li><FaCheckCircle/><span>Unlimited</span> creations</li>
        <li><FaCheckCircle/><span>Unlimited</span> chat messages</li>
        <li><FaCheckCircle/><span>Dall-e 3</span> higher quality images</li>
        <li style={{display: 'none'}}><FaCheckCircle/><span>Add many more</span> descriptions</li>
        <li style={{display: 'none'}}><FaCheckCircle/><span>Regenerate</span> images</li>
        <li style={{display: 'none'}}><FaCheckCircle/><span>Make image from chat</span> message</li>
      </ul>
      <form action={apiUrl} method="POST">
        <input type="hidden" name="lookup_key" value="ccpt3" />
        <input type="hidden" name="token" value={token} />
        <div className="upsell-modal-actions">
          {closeUpsellModal !== false && (<button type="button" onClick={closeUpsellModal}>No thanks</button>)}
          <button id="checkout-and-portal-button" type="submit">
            {price}/mo
          </button>
        </div>
      </form>
      
    </div>
  );
};

export default Sales;
