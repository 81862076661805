import React, { useState, useEffect, useRef } from 'react';
import TagsDisplay from './TagsDisplay';

const TagsModal = ({ tagsList, mode = "new", onTagsChange, tagToEdit = {}, closeModal, setActiveTag= false }) => {
  const [tags, setTags] = useState(tagsList);
  const [tagName, setTagName] = useState('');
  const [tagDescription, setTagDescription] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [editIndex, setEditIndex] = useState(-1);

  // Create a ref for the input field
  const inputRef = useRef(null);

   useEffect(() => {
    // Automatically focus the input field when the modal is opened or when switching between edit and create mode
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);

    useEffect(() => {
    // Focus the input field when the modal is opened
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

useEffect(() => {
      if(tags.length > 0)
        localStorage.setItem('tags', JSON.stringify(tags));
    }, [tags]);


  const handleAddTag = () => {
    if(tagName.length == 0)
      return;
  let updatedTags;
  if (isEditing) {
    updatedTags = [...tags];
    updatedTags[editIndex] = { name: tagName, description: tagDescription };
  } else {
    setActiveTag({ name: tagName, description: tagDescription });
    updatedTags = [...tags, { name: tagName, description: tagDescription }];
  }

  setTags(updatedTags);
  onTagsChange(updatedTags); // Notify parent about the change
  handleSaveTag(tagName,tagDescription);
  localStorage.setItem('tags', JSON.stringify(updatedTags));
  // Reset state
  setIsEditing(false);
  setTagName('');
  setTagDescription('');
  setEditIndex(-1);
  closeModal();
};

  const handleEditTag = (index) => {
    setIsEditing(true);
    setEditIndex(index);
    setTagName(tags[index].name);
    setTagDescription(tags[index].description);
  };

  const handleDeleteTag = () => {
  const filteredTags = tags.filter((_, index) => index !== editIndex);
  setTags(filteredTags);
  onTagsChange(filteredTags); // Notify parent about the change
  handleSaveDeleteTag(tagName,tagDescription);
  // Reset state
  setIsEditing(false);
  setEditIndex(-1);
  setTagName('');
  setTagDescription('');
};

  const handleSaveDeleteTag = async (tagName, tagDescription) => {
    let apiUrl = 'http://localhost:3001/my_tags/'; // Default URL for local development
    // Change API URL if the hostname is not localhost
    if (window.location.hostname === 'charactercomposer.com') {
      apiUrl = 'https://charactercomposer.com/my_tags/';
    }
    const tagData = {
      type: 'save',
      tag: { name: tagName, description: tagDescription },
      deleteTag:true
    };
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'API-Key': "2(yY62.mG#6UI324324", // Ensure this API key is securely handled in a real application
          'Authorization': `Bearer ${localStorage.getItem('token')}`, // Fetch the token from localStorage or your state management
        },
        body: JSON.stringify(tagData),
      });

      const result = await response.json();
      if (response.ok) {
        console.log('Tag added successfully:', result);
        // Update your state or UI based on the result
      } else {
        console.error('Failed to add tag:', result.error);
        // Handle errors, such as displaying a notification to the user
      }
    } catch (error) {
      console.error('Error adding tag:', error);
      // Handle network errors or unexpected issues here
    }
  };

  const handleSaveTag = async (tagName, tagDescription) => {
    let apiUrl = 'http://localhost:3001/my_tags/'; // Default URL for local development
    // Change API URL if the hostname is not localhost
    if (window.location.hostname === 'charactercomposer.com') {
      apiUrl = 'https://charactercomposer.com/my_tags/';
    }
    const tagData = {
      type: 'save',
      tag: { name: tagName, description: tagDescription }
    };
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'API-Key': "2(yY62.mG#6UI324324", // Ensure this API key is securely handled in a real application
          'Authorization': `Bearer ${localStorage.getItem('token')}`, // Fetch the token from localStorage or your state management
        },
        body: JSON.stringify(tagData),
      });

      const result = await response.json();
      if (response.ok) {
        console.log('Tag added successfully:', result);
        // Update your state or UI based on the result
      } else {
        console.error('Failed to add tag:', result.error);
        // Handle errors, such as displaying a notification to the user
      }
    } catch (error) {
      console.error('Error adding tag:', error);
      // Handle network errors or unexpected issues here
    }
  };

  return (
    <div className="tagsModal">
      <h3>{isEditing ? 'Edit Tag' : 'Create Tag'}</h3>
      <p>Use tags to create consistant rules across your creations!</p>
      {isEditing ? <button className="makeNewTag" onClick={() => { setIsEditing(false); setTagName(""); setTagDescription(""); }}>or create new tag</button> : ''}
      <div>
      <input
        type="text"
        placeholder="Tag Name"
        value={tagName}
        onChange={(e) => setTagName(e.target.value)}
        ref={inputRef}
      />
      <textarea
        placeholder="Tag Details (Optional)"
        value={tagDescription}
        onChange={(e) => setTagDescription(e.target.value)}
      />
      </div>
      {isEditing && (
        <button className="deleteTag"onClick={handleDeleteTag}>Delete Tag</button>
      )}
      <button className="saveTag" onClick={handleAddTag}>{isEditing ? 'Save Changes' : 'Create Tag'}</button>
      {tags.length > 0 && (
        <div className="existingTags">
          <h4>Edit Existing Tags</h4>
          <TagsDisplay
            tags={tags}
            onEdit={handleEditTag}
            showAddTag={false}
          />
        </div>
      )}
      
    </div>
  );
};

export default TagsModal;
