export const FieldsNarrativeFaction = [
    {name: 'Name', value: '', order: 0, heading: '', custom: false, simple: true},
    {name: 'Genre', value: '', simple: true, order: 0, heading: 'Basic', custom: false,
      dropDownOptions: [
        "Fantasy",
        "Steampunk",
        "Science-Fiction",
        "Space Opera",
        "Cyberpunk",
        "Post-Apocalyptic",
        "Modern Day",
        "Wild West",
        "Lovecraftian",
        "Folklore",
        "Superhero",
        "Supernatural",
        "Historical",
        "Magical Realism",
        "Dystopian",
        "Alternate History",
        "Speculative Fiction",
        "Detective",
        "Toyland",
        "Spiritual",
        "Urban Fantasy",
        "Space Western",
        "Biopunk",
        "Solarpunk",
        "Dieselpunk",
        "Mythology",
        "Fairytale",
        "Anthropomorphic",
        "Hard Sci-Fi",
        "Martial Arts",
        "Weird Fiction",
        "Pulp Fiction",
        "Dark Fantasy",
        "Apocalyptic",
        "Prehistoric"
        ]
    },
    {name: 'Type', value: '', simple: true,  order: 0, heading: 'Basic', custom: false, 
      dropDownOptions: ["Rebel Alliance", "Royal Dynasty", "Trade Guild", "Religious Sect", "Bandit Gang", "Mercenary Company", "Crime Syndicate", "Secret Society", "Space-faring Fleet", "Scientific Research Collective", "Mage Circle", "Political Party", "Environmental Activist Group", "Cybernetic Cult", "Philosopher's Club", "Pirate Crew", "Tribal Confederation", "Knightly Order", "Alien Hive Mind", "Explorer's Society", "Underground Resistance", "Military Junta", "Spy Network", "Vampire Clan", "Artisan's Guild", "Nomadic Caravan", "University Department", "Multinational Corporation", "Union of Miners", "Society of Time Travellers"]
    },
    {name: 'Story Tone', value: '', simple: true,  order: 0, heading: 'Basic', custom: false, 
      dropDownOptions: [
        "Horror",
        "Comedy",
        "Noir",
        "Action",
        "Crime",
        "Thriller",
        "Adventure",
        "Epic",
        "Gothic",
        "Grimdark",
        "Realistic",
        "Tragedy",
        "Romance",
        "Family-Friendly",
        "Military",
        "Supernatural",
        "Soap Opera",
        "Mystery",
        "Drama",
        "Psychological",
        "Surreal",
        "Political",
        "Coming Of Age",
        "Situational Comedy",
        "Espionage",
        "Satire",
        "Revenge",
        "Survival",
        "Heist",
        "Caper",
        "Time Travel",
        "Alien Encounter",
        "Conspiracy",
        ]
    },
    {name: 'Membership Size', value: '', simple:true, order: 0, heading: 'Basic', custom: false, 
      dropDownOptions: ["Tight-Knit Circle", "Handful of Devotees", "Select Few", "Small Coterie", "Modest Assembly", "Decent-Sized Contingent", "Significant Grouping", "Large Coalition", "Sizeable Gathering", "Substantial Legion", "Extensive Network", "Widespread Community", "Enormous Horde", "Sprawling Alliance", "Massive Order", "Multitude", "Numerous Swarm", "Crowd", "Sea of Followers", "Vast Congregation", "Immense Collective", "Prolific Association", "Great Many", "Sizable Army", "Widespread Diaspora", "Innumerable Masses", "Legion", "Host", "Galaxy of Adherents", "Ocean of Supporters"]
    },
    {name: 'Faction Scale', value: '', simple: true,  order: 0, heading: 'Basic', custom: false, 
      dropDownOptions: ["Neighborhood Level", "Community Based", "City-Wide", "Metropolitan", "County Level", "Regional", "Tri-City Area", "State Level", "National", "Bi-National ", "Transnational", "Continental", "Pan-Continental", "Hemispheric ", "Global", "Planetary ", "Multiplanetary ", "Solar System Level", "Interstellar ", "Galactic ", "Intergalactic ", "Galactic Cluster", "Galactic Supercluster", "Cosmological ", "Multidimensional", "Subterranean ", "Underwater", "Airborne ", "Interplanar", "Virtual "]
    },
    {name: 'Organizational Structure', value: '', order: 0, heading: 'Structure & Leadership', custom: false, 
      dropDownOptions: ["Hierarchy", "Democracy", "Anarchy", "Monarchy", "Oligarchy", "Theocracy", "Meritocracy", "Technocracy", "Dictatorship", "Plutocracy", "Stratocracy (Military Government)", "Autocracy", "Bureaucracy", "Federalism", "Confederacy", "Syndicalism", "Corporatocracy (Corporate Rule)", "Matriarchy", "Patriarchy", "Egalitarianism", "Timocracy (Rule by the Honorable)", "Geniocracy (Rule by the Intelligent)", "Kraterocracy (Rule by the Strong)", "Noocracy (Rule by the Wise)", "Kleptocracy (Rule by the Thieves)", "Gerontocracy (Rule by the Elderly)", "Kakistocracy (Rule by the Worst)", "Kritarchy (Rule by Judges)", "Nomocracy (Rule by Law)", "Anarcho-Syndicalism"]
    },
    {name: 'Leader', value: '', simple: true,  order: 0, heading: 'Structure & Leadership', custom: false, 
    dropDownOptions:[] 
    },
    {name: 'Leadership Style', value: '', order: 0, heading: 'Structure & Leadership', custom: false, 
      dropDownOptions: ["Hereditary", "Elective", "Appointment by Predecessor", "Prophecy", "Reincarnation", "Challenge or Duel", "Appointment by Council ", "Divine Revelation", "Random Lot", "Survival of the Fittest", "Merit-Based ", "Military Rank", "Wealth", "Age ", "Last Man Standing", "By Ordeal or Test", "Rite of Passage", "Closest Relative", "Assigned by an Outside Power", "Usurpation", "Assassination", "Primogeniture", "Appointment by Oracle ", "Popular Vote", "Tenure", "Rotation ", "Matrilineal/Matrifocal ", "Patrilineal/Patrifocal", "Sacred Object ", "Conquest"]
    },
    {name: 'Notable Positions', value: '', order: 0, heading: 'Structure & Leadership', custom: false, 
      dropDownOptions: ["Chapter Master", "High Priest", "Enforcer", "Bagman", "Spymaster", "Elder", "Quartermaster", "Chief Medic", "Head Alchemist", "Master-at-Arms", "Chief Engineer", "Grand Mage", "Herald", "Captain of the Guard", "Chief Diplomat", "Treasurer", "Lorekeeper", "Oracle", "Master Artisan", "Propagandist", "Chief Negotiator", "Tactician", "Field Marshal", "Master of Ceremonies", "Chief Scribe", "Inquisitor", "Prophet", "Beastmaster", "Chief Biologist", "Head of Recruitment"]
    },
    {name: 'Core Beliefs', value: '', simple: true,  order: 0, heading: 'Ideals & Goals', custom: false, 
      dropDownOptions: ["Pacifism", "Militarism", "Collectivism", "Individualism", "Environmentalism", "Technocracy", "Monasticism", "Hedonism", "Humanism", "Nihilism", "Meritocracy", "Fatalism", "Existentialism", "Materialism", "Spiritualism", "Utilitarianism", "Asceticism", "Egalitarianism", "Xenophobia", "Xenophilia", "Ancestor Worship", "Transhumanism", "Conservatism", "Progressivism", "Nationalism", "Globalism", "Secularism", "Theocracy", "Vegetarianism / Veganism", "Animal Rights Advocacy"]
    },
    {name: 'Faction Goal', value: '', order: 0, heading: 'Ideals & Goals', custom: false,
      dropDownOptions: ["Overthrowing a government or ruler", "Establishing a new order or system", "Spreading a religious belief or ideology", "Seeking vengeance or justice", "Controlling a valuable resource", "Gaining political influence or power", "Amassing wealth", "Protecting a specific group or interest", "Discovering or preserving knowledge", "Achieving technological advancement", "Defending or conquering territory", "Surviving an existential threat", "Pursuing immortality", "Advocating for social reform or equality", "Colonizing or exploring new territories or planets", "Uplifting or empowering a specific class or group", "Uncovering or guarding ancient artifacts or secrets", "Restoring a previous state or golden age", "Winning a war or achieving peace", "Achieving independence or self-determination", "Preventing an impending disaster or prophecy", "Securing the succession of a specific leader or lineage", "Advancing a scientific theory or experiment", "Maintaining a tradition or way of life", "Revolutionizing an industry or economy", "Reshaping society according to a utopian vision", "Eradicating a specific threat or enemy", "Bringing about the end of the world or an apocalypse", "Creating an artistic or cultural renaissance", "Achieving harmony with nature or other species"]
    },
    {name: 'Tactics to Achieve Goal', value: '', order: 0, heading: 'Ideals & Goals', custom: false, 
      dropDownOptions: ["Overthrowing a government", "Establishing a new order", "Spreading a religious belief", "Seeking vengeance", "Controlling a valuable resource", "Gaining political influence", "Amassing wealth", "Protecting a specific group", "Discovering new knowledge", "Achieving technological advancement", "Defending territory", "Surviving an existential threat", "Pursuing immortality", "Advocating for social reform", "Colonizing new territories", "Uplifting a specific class", "Uncovering ancient artifacts", "Restoring a previous state", "Winning a war", "Achieving peace", "Achieving independence", "Securing the succession of a specific leader", "Advancing a scientific theory", "Maintaining a tradition", "Preventing an impending disaster", "Revolutionizing an industry", "Reshaping society to a utopian vision", "Eradicating a specific enemy", "Creating an artistic renaissance", "Achieving harmony with nature"]
    },
    {name: 'Stance Towards Outsiders', value: '', order: 0, simple: true,  heading: 'Ideals & Goals', custom: false, 
      dropDownOptions: ["Openly hostile", "Suspicious", "Indifferent", "Curious", "Welcoming", "Defensive", "Subservient", "Dominant", "Envious", "Reverent", "Contemptuous", "Protective", "Opportunistic", "Manipulative", "Admiring", "Competitive", "Fearful", "Respectful", "Generous", "Isolationist", "Exploitative", "Collaborative", "Deceptive", "Secretive", "Submissive", "Imitative", "Dependent", "Pitying", "Mercenary", "Hero-worshipping"]
    },
    {name: 'Overall Power Level', value: '', order: 0, heading: 'Power & Influence', custom: false, 
      dropDownOptions: ["Absolute Control", "Small-Time", "Growing Influence", "Dominant Force", "On the Decline", "Emerging Power", "Shadow Force", "Unchecked Authority", "Marginalized Group", "Underdogs", "Respected Entity", "Feared Organization", "Insurgent Force", "Power Behind the Scenes", "Regional Influence", "Global Reach", "Balanced Power", "Unseen Hand", "Waning Authority", "Contested Power", "Struggling Uprising", "Dormant Giant", "Decentralized Network", "Surging Upstart", "Neglected Faction", "Ubiquitous Presence", "Outsider Entity", "Rising Star", "Fledgling Group", "Stagnant Power"]
    },
    {name: 'Sphere of Influence', value: '', order: 0, heading: 'Power & Influence', custom: false, 
      dropDownOptions: ["Political", "Cultural", "Spiritual", "Occult", "Criminal", "Economic", "Technological", "Educational", "Scientific", "Military", "Environmental", "Artistic", "Legal", "Humanitarian", "Media", "Religious", "Healthcare", "Sports", "Transportation", "Real Estate", "Entertainment", "Trade and Commerce", "Animal Rights", "Space Exploration", "Maritime", "Fashion", "Food and Agriculture", "Labor and Employment", "Indigenous Rights", "Historical Preservation"]
    },
    {name: 'Faction Motto', value: '', order: 0, heading: 'Power & Influence', custom: false,
      dropDownOptions: ["United We Stand", "For the Greater Good", "Strength Through Sacrifice", "In Pursuit of Truth", "Fortune Favors the Bold", "Justice Above All", "Knowledge is Power", "To Protect and Serve", "By the Sword We Seek Peace", "Beneath the Stars, We Rise", "In Unity, Victory", "Peace Through Power", "Progress Above All", "Unseen, Unheard, Unyielding", "For Every End, a New Beginning", "In Darkness, We Shine", "Always Forward, Never Back", "Through Adversity, To the Stars", "Honor Above All", "Rise Like the Phoenix", "In Chaos, There is Fertility", "From the Ashes, We Rebuild", "Freedom or Death", "Perseverance Conquers", "In Blood, Unity", "Destiny Awaits", "The Future is Ours", "No Chains, No Masters", "Through Trials, Triumph", "We Are the Storm"]
    },
    {name: 'Faction Symbol', value: '', order: 0, heading: 'Power & Influence', custom: false,
      dropDownOptions: ["Phoenix Rising", "Lion's Head", "Iron Fist", "Olive Branch", "All-Seeing Eye", "Open Book", "Dragon's Claw", "Crescent Moon", "Anvil and Hammer", "Sword and Shield", "Stylized Star", "Wreath of Laurel", "A Distant Planet", "Dove in Flight", "Anchored Ship", "Pair of Scales", "Coiled Serpent", "Tree of Life", "Lightning Bolt", "Wolf's Head", "Radiant Sun", "Mountain Peak", "The Rose", "Fleur-de-lis", "Crow on a Skull", "Plumed Quill", "A Burning Torch", "Crossed Keys", "Crown of Thorns", "A Broken Chain"]
    },
    {name: 'Allies', value: '', order: 0, heading: 'Relationships & Reputation', custom: false,
    dropDownOptions:[]
    },
    {name: 'Enemies', value: '', order: 0, heading: 'Relationships & Reputation', custom: false,
    dropDownOptions:[]
    },
    {name: 'Reputation', value: '', order: 0, heading: 'Relationships & Reputation', custom: false,
      dropDownOptions: ["Feared", "Respected", "Admired", "Reviled", "Mysterious", "Influential", "Trustworthy", "Radical", "Inspiring", "Despised", "Elusive", "Ruthless", "Charitable", "Heroic", "Powerful", "Underestimated", "Peaceful", "Corrupt", "Reliable", "Traditional", "Visionary", "Tyrannical", "Revolutionary", "Humble", "Incompetent", "Venerated", "Renegade", "Merciful", "Ambitious", "Disgraced"]
    },
    {name: 'Recent Conflicts', value: '', order: 0, heading: 'History & Culture', custom: false,
      dropDownOptions: ["Territorial dispute", "Succession crisis", "Resource scarcity", "Ideological disagreement", "Religious schism", "Cultural clash", "Class struggle", "Power vacuum", "Trade war", "Tech race", "Espionage rivalry", "Propaganda warfare", "Guerrilla insurgency", "Civil rights movement", "Nuclear standoff", "Gang warfare", "Magic supremacy battle", "Ethnic tension", "Inter-species conflict", "Space colonization conflict", "Information warfare", "Alien invasion resistance", "Pirate/privateer antagonism", "Ancestral feud", "Inheritance dispute", "Secessionist movement", "Demon summoning rivalry", "Corporate espionage", "Biological warfare", "Immortality race"]
    },
    {name: 'Historical Conflicts', value: '', order: 0, heading: 'History & Culture', custom: false,
    dropDownOptions:[]
    },
    {name: 'Traditions', value: '', order: 0, heading: 'History & Culture', custom: false,
      dropDownOptions: ["Passing down heirlooms", "Marking of age with a specific act", "Oral storytelling", "Daily prayers or affirmations", "Use of traditional garb or uniform", "Regular communal meals", "Use of a specific greeting or salutation", "Tattooing or body modifications", "Guided meditations or philosophical discussions", "Training in a specific skill or art", "Strict adherence to a code of honor", "Silence during specific times or situations", "Apprenticeship in a trade or skill", "Group hunts or foraging trips", "Publicly speaking vows or oaths", "Use of specific language or dialect", "Annual pilgrimage to a significant location", "Practice of martial arts or weapon training", "Creation of symbolic art or craft", "Strict dietary practices or restrictions", "Keeping of written records or logs", "Use of specific forms of address or titles", "Maintaining an eternal flame or symbol", "Regular fasts or periods of abstinence", "Sleeping under open sky or specific conditions", "Care and preservation of ancient texts", "Symbolic gifting or exchange of items", "Singing or recitation of faction-specific songs or poems", "Ritualistic cleaning or purification routines", "Use of a specific method of decision making"]
    },
    {name: 'Rituals', value: '', order: 0, heading: 'History & Culture', custom: false,
      dropDownOptions: ["Initiation rites for new members", "Communion with a divine or spiritual entity", "Full moon gatherings", "Ritual combat or dueling", "Ancestor worship ceremonies", "Seances or spirit communications", "Divination or future-telling practices", "Ritual of Forgiveness or Absolution", "Daily sunrise or sunset observances", "Sacrificial offerings", "Healing ceremonies or rituals", "Ritual dance or movement practices", "Meditation or trance rituals", "Harvest or planting rituals", "Sacred meals or feasts", "Vision quests or spirit journeys", "Ritualized debates or discussions", "Tattooing or scarring ceremonies", "Rite of Passage for milestones", "Rituals for summoning or banishing entities", "Memory sharing or mind-melding practices", "Ceremonies for the changing of seasons", "Chanting or mantra practices", "Rituals for crafting sacred objects", "Fasting or purging ceremonies", "Ceremonies for the naming of individuals", "Celebratory rituals for victories or accomplishments", "Rituals for the dead or dying", "Oath swearing ceremonies", "Rituals for marking territory or space"]
    },
    {name: 'Events', value: '', order: 0, heading: 'History & Culture', custom: false,
      dropDownOptions: ["Founding Day", "New Year's Feast", "Harvest Festival", "Winter Solstice Vigil", "Spring Planting Celebration", "Day of Remembrance", "Summer Games", "Autumn Equinox Gathering", "Heroes' Day", "Festival of Lights", "Freedom Day", "Lunar Blessing Festival", "Day of Silence", "Grand Tournament", "Midsummer Night's Revel", "Feast of Valor", "Elders' Day", "The Great Hunt", "Craftsman's Fair", "Market Festival", "Sacred Flame Festival", "Night of Storytelling", "The Ascension Ritual", "Day of Reflection", "Celebration of Life", "The Great Assembly", "Pilgrimage Day", "The Passing of Seasons Festival", "Feast of the First Snow", "Starlight Celebration"]
    },
    {name: 'Additional Details', value: '', order: 0, heading: 'Background', custom: false},
    {name: 'Setting (user generated)', value: '', order: 0, simple:true, heading: 'Background', custom: false, dropDownOptions: [], specialDropdown: true, specialDropdownType: 'Narrative', displayOutsideAccordian:true, attachedObject: {}},
    {name: 'Within Location (user generated)', value: '', displayOutsideAccordian:true, order: 0, simple:true, heading: 'Background', custom: false, dropDownOptions: [], specialDropdown: true, specialDropdownType: 'Location', attachedObject: {}},
  ];
