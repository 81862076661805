import React, { useState, useEffect } from 'react';
import Spinner from './Spinner'; // Import the Spinner component

const tips = [
  'To generate a fully random creation, simply click the <strong>Compose</strong> button without filling in any fields.',
  'Not sure where to start? Click the <strong>Compose</strong> button to create something cool!',
  'If you fill in some fields and click <strong>Compose</strong>, the remaining empty fields will be generated to create a unique creation based on your inputs.',
  'Click the <strong>dice</strong> icon next to the name field to generate an appropriate name. Try doing it after filling other important details for the best results!',
  'Looking for a name generator? Add your characters details then click the <strong>name dice</strong> to generate appropriate and interesting names.',
  'If you click the <strong>generate all fields</strong> button, any empty fields will be randomly generated based on your existing inputs.<br/>',
  'Experiment with mixing unusual traits, backgrounds, or skills to create a unique and memorable character.',
  'Incorporate character flaws or weaknesses to make them more relatable and human, while also providing opportunities for growth and development.',
  'Play with character archetypes, but don\'t be afraid to subvert expectations or combine multiple archetypes to create a more complex and original character.',
  'Try only providing the most important details about your character and let the generator do the rest for most interesting results.',
  'Clicking the dice icon next to any field will generate an appropriate attribute for your creation.',
  'Add custom fields and have the generator fill in the values for you!'
];

const loadingTexts = [
  'Building details...',
  'Creating appearance...',
  'Growing personality...',
  'Developing backstory...',
  'Crafting skills...',
  'Forming relationships...',
  'World-building in progress...',
  'Training the character to avoid spoilers...',
  'Calculating the odds of a heroic journey...',
  'Fine-tuning character quirks...',
  'Infusing character with magical powers...',
  'Assembling an epic quest...',
  'Ensuring the character\'s pet is house-trained...',
  'Prepping character for spontaneous dance numbers...',
  'Generating memorable catchphrases...',
  'Designing a stylish wardrobe...',
  'Discovering hidden talents and abilities...',
  'Establishing character\'s favorite hobbies...',
  'Preparing character for thrilling adventures...',
  'Building a network of allies and rivals...',
  'Infusing character with wit and wisdom...',
  'Setting the stage for epic triumphs and defeats...',
  'Weaving intricate storylines...',
  'Uncovering dark secrets...',
  'Determining character\'s favorite food...',
  'Exploring the character\'s family tree...',
  'Fleshing out character motives...',
  'Inventing languages and dialects...',
  'Crafting character theme music...',
  'Integrating regional customs and traditions...',
  'Assembling a well-rounded supporting cast...',
  'Incorporating character flaws for depth...',
  'Establishing character habits and rituals...',
  'Assigning character strengths and weaknesses...',
  'Plotting character growth and development...',
  'Creating immersive settings...',
  'Generating a detailed timeline...',
  'Choosing character clothing and accessories...',
  'Determining character\'s moral compass...',
  'Outlining character\'s dreams and aspirations...',
  'Exploring character\'s fears and phobias...',
  'Building character\'s personal philosophy...',
  'Inventing character-specific hobbies...',
  'Developing character\'s social circle...',
  'Generating character\'s favorite books and movies...',
  'Exploring character\'s romantic interests...',
  'Designing character\'s living space...',
  'Determining character\'s mode of transportation...',
  'Creating character\'s profession or occupation...',
  'Selecting character\'s preferred weapons...',
  'Generating character nicknames...',
  'Inventing character anecdotes...',
  'Designing character tattoos and markings...',
  'Constructing character backstory twists...',
  'Choosing character\'s favorite season...',
  'Incorporating regional accents and speech patterns...',
  'Assembling a dynamic rogues\' gallery...',
  'Creating character family dynamics...',
  'Outlining character\'s personal growth...',
  'Designing character\'s secret hideout...',
  'Assigning character\'s place of origin...',
  'Incorporating character\'s belief system...',
  'Determining character\'s favorite music...',
  'Exploring character\'s past relationships...',
  'Uncovering character\'s secret talents...',
  'Designing character\'s preferred attire...',
  'Establishing character\'s motto or mantra...',
  'Developing character\'s favorite pastimes...',
  'Inventing character\'s favorite games and sports...',
  'Building character\'s personal code of ethics...',
  'Assigning character\'s lucky charm or talisman...',
  'Creating character\'s favorite fictional heroes...',
  'Exploring character\'s favorite places to visit...',
  'Designing character\'s home environment...',
  'Establishing character\'s preferred fighting style...',
  'Determining character\'s superstitions and omens...',
  'Incorporating character\'s role in the story...',
  'Creating character\'s goals and motivations...',
  'Developing character\'s favorite phrases and idioms...',
  'Designing character\'s prized possessions...',
  'Choosing character\'s preferred cuisine...',
  'Determining character\'s guilty pleasures...',
  'Outlining character\'s daily routines...',
  'Exploring character\'s pet peeves and annoyances...',
  'Building character\'s trust in others...',
  'Inventing character\'s favorite myths and legends...',
  'Incorporating character\'s deepest regrets...',
  'Developing character\'s unexplored potential...',
  'Determining character\'s favorite hangouts...',
  'Designing character\'s family crest or emblem...',
  'Building character\'s connections to other characters...',
  'Developing character\'s friendships and alliances...',
  'Exploring character\'s rivalries and conflicts...',
  'Outlining character\'s life goals and ambitions...',
  'Creating character\'s favorite memories...',
  'Assigning character\'s favorite colors...',
  'Determining character\'s personal style...',
  'Incorporating character\'s favorite scents...',
  'Designing character\'s dream vacation...',
  'Establishing character\'s sense of humor...',
  'Developing character\'s taste in art and culture...',
  'Inventing character\'s hobbies and interests...',
  'Exploring character\'s secrets and hidden desires...',
  'Building character\'s support network...',
  'Choosing character\'s favorite beverages...',
  'Creating character\'s favorite ways to relax...',
  'Determining character\'s favorite weather...',
  'Incorporating character\'s favorite plants and animals...',
  'Designing character\'s most significant moments...',
  'Establishing character\'s sense of loyalty...',
  'Developing character\'s favorite forms of entertainment...',
  'Outlining character\'s favorite books and authors...',
  'Creating character\'s favorite movies and directors...',
  'Exploring character\'s favorite sounds and noises...',
  'Designing character\'s favorite celebrations and holidays...',
  'Determining character\'s favorite means of communication...',
  'Incorporating character\'s favorite ways to travel...'
];


const LoadingBox = ({ loading = false, show_tips = true }) => {
  const [currentTip, setCurrentTip] = useState(0);
  const [fade, setFade] = useState(false);

  const getRandomTip = (currentTipIndex) => {
    let randomIndex;
    do {
      randomIndex = Math.floor(Math.random() * tips.length);
    } while (randomIndex === currentTipIndex);
    return randomIndex;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(true);
      setTimeout(() => {
        setCurrentTip((prevTip) => getRandomTip(prevTip));
        setFade(false);
      }, 500); // Fade out duration (same as the CSS transition duration)
    }, 14000); // Change the tip every 12 seconds

    return () => {
      clearInterval(interval);
    };
  }, []);

  const [currentLoadingText, setCurrentLoadingText] = useState(0);
  const [randomLoadingTextOrder, setRandomLoadingTextOrder] = useState([]);

  const getRandomLoadingText = (currentTextIndex) => {
    let randomIndex;
    do {
      randomIndex = Math.floor(Math.random() * loadingTexts.length);
    } while (randomIndex === currentTextIndex);
    return randomIndex;
  };
useEffect(() => {
    const randomizeLoadingTextOrder = () => {
      const order = [...Array(loadingTexts.length).keys()];
      for (let i = order.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [order[i], order[j]] = [order[j], order[i]];
      }
      return order;
    };

    setRandomLoadingTextOrder(randomizeLoadingTextOrder());
  }, []);
  useEffect(() => {
    let timeout;
    if (loading) {
      const randomDuration = Math.floor(Math.random() * 4000) + 2500; // Random duration between 1s and 3s
      timeout = setTimeout(() => {
        setCurrentLoadingText((prevText) => (prevText + 1) % loadingTexts.length);
      }, randomDuration);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [loading, currentLoadingText]);



  return (
    <div className="LoadingBox" id="LoadingBox">
    {show_tips &&
      <div className="tipsContainer">
        <div className={`TipCards ${fade ? 'fadeInOut' : ''}`} >
          <h3 className={`${fade ? 'fadeInOut' : ''}`}>Composing Tip {currentTip + 1}</h3>
          <p className={`tip ${fade ? 'fadeInOut' : ''}`} dangerouslySetInnerHTML={{ __html: tips[currentTip] }} />
        </div>
      </div>
    }
      {loading && 
        <div className="loading-character">
          <Spinner />
          <p className="loading-text">{loadingTexts[randomLoadingTextOrder[currentLoadingText]]}</p>
        </div>
      }
    </div>
  );
};

export default LoadingBox;