import React from 'react';

const Cookies = ({}) => {
  return (
    <div className="terms-container">
      <div className="terms-and-conditions">
         <h1>Cookie Policy</h1>
    <p><strong>Last updated August 23, 2023</strong></p>
    <p>This Cookie Policy explains how Character Composer ("Company," "we," "us," and "our") uses cookies and similar technologies to recognize you when you visit our website at charactercomposer.com ("Website"). It explains what these technologies are and why we use them, as well as your rights to control our use of them.</p>

    <p>In some cases, we may use cookies to collect personal information, or that becomes personal information if we combine it with other information.</p>

    <h2>What are cookies?</h2>
    <p>Cookies are small data files that are placed on your computer or mobile device when you visit a website. Cookies are widely used by website owners to make their websites work, or to work more efficiently, as well as to provide reporting information.</p>

    <p>Cookies set by the website owner (in this case, Character Composer) are called "first-party cookies." Cookies set by parties other than the website owner are called "third-party cookies." Third-party cookies enable third-party features or functionality to be provided on or through the website (e.g., advertising, interactive content, and analytics). The parties that set these third-party cookies can recognize your computer both when it visits the website in question and also when it visits certain other websites.</p>

    <h2>Why do we use cookies?</h2>
    <p>We use first- and third-party cookies for several reasons. Some cookies are required for technical reasons in order for our Website to operate, and we refer to these as "essential" or "strictly necessary" cookies. Other cookies also enable us to add enhanced functionalities such as remembering your selections and custom form fields between sessions. We do not use any advertising cookies.</p>

    <h2>How can I control cookies?</h2>
    <p>You have the right to decide whether to accept or reject cookies. You can exercise your cookie rights by setting your preferences in the Cookie Consent Manager. Essential cookies cannot be rejected as they are strictly necessary to provide you with services. The Cookie Consent Manager allows you to select which categories of cookies you accept or reject.</p>

    <p>The Cookie Consent Manager can be found in the notification banner and on our website. If you choose to reject cookies, you may still use our website though your access to some functionality and areas of our website may be restricted. You may also set or amend your web browser controls to accept or refuse cookies.</p>

    <h2>How can I control cookies on my browser?</h2>
    <p>As the means by which you can refuse cookies through your web browser controls vary from browser to browser, you should visit your browser's help menu for more information. The following is information about how to manage cookies on the most popular browsers:</p>
    <ul>
        <li>Chrome</li>
        <li>Internet Explorer</li>
        <li>Firefox</li>
        <li>Safari</li>
        <li>Edge</li>
        <li>Opera</li>
    </ul>

    <p>In addition, most advertising networks offer you a way to opt out of targeted advertising. If you would like to find out more information, please visit:</p>
    <ul>
        <li>Digital Advertising Alliance</li>
        <li>Digital Advertising Alliance of Canada</li>
        <li>European Interactive Digital Advertising Alliance</li>
    </ul>

    <h2>What about other tracking technologies, like web beacons?</h2>
    <p>Cookies are not the only way to recognize or track visitors to a website. We may use other, similar technologies from time to time, like web beacons (sometimes called "tracking pixels" or "clear gifs"). These are tiny graphics files that contain a unique identifier that enables us to recognize when someone has visited our Website or opened an email including them. This allows us, for example, to monitor the traffic patterns of users from one page within a website to another, to deliver or communicate with cookies, to understand whether you have come to the website from an online advertisement displayed on a third-party website, to improve site performance, and to measure the success of email marketing campaigns. In many instances, these technologies are reliant on cookies to function properly, and so declining cookies will impair their functioning.</p>

    <h2>Do you use Flash cookies or Local Shared Objects?</h2>
    <p>Websites may also use so-called "Flash Cookies" (also known as Local Shared Objects or "LSOs") to, among other things, collect and store information about your use of our services, fraud prevention, and for other site operations.</p>

    <p>If you do not want Flash Cookies stored on your computer, you can adjust the settings of your Flash player to block Flash Cookies storage using the tools contained in the Website Storage Settings Panel. You can also control Flash Cookies by going to the Global Storage Settings Panel and following the instructions.</p>

    <p>Please note that setting the Flash Player to restrict or limit acceptance of Flash Cookies may reduce or impede the functionality of some Flash applications, including, potentially, Flash applications used in connection with our services or online content.</p>

    <h2>Do you serve targeted advertising?</h2>
    <p>No.</p>

    <h2>Contact us</h2>
    <p>If you have any questions about our Cookie Policy, please contact us by:</p>
    <p>Email: <a href="mailto:support@charactercomposer.com">support@charactercomposer.com</a></p>
</div>

    </div>
  );
};

export default Cookies;
