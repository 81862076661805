export const FieldsItemArmor = [
    {name: 'Name', value: '', order: 0, heading: '', simple:true, custom: false},
    {name: 'Setting', value: '', order: 0, heading: 'Basic', simple:true, custom: false,
      dropDownOptions: ['Fantasy','Steampunk','Science-Fiction','Space Opera','Cyberpunk','Post-Apocalyptic','Modern Day','Wild West','Lovecraftian','Folklore','Superhero','Supernatural','Historical','Magical Realism','Dystopian','Alternate History','Speculative Fiction','Detective','Toyland','Spiritual','Urban Fantasy','Space Western','Biopunk','Solarpunk','Dieselpunk','Mythology','Fairytale','Anthropomorphic','Hard Sci-Fi','Martial Arts','Weird Fiction','Pulp Fiction','Dark Fantasy','Apocalyptic','Prehistoric','Setting','Fantasy','Steampunk','Science-Fiction','Space Opera','Cyberpunk','Post-Apocalyptic','Modern Day','Wild West','Lovecraftian','Folklore','Superhero','Supernatural','Historical','Magical Realism','Dystopian','Alternate History','Speculative Fiction','Detective','Toyland','Spiritual','Urban Fantasy','Space Western','Biopunk','Solarpunk','Dieselpunk','Mythology','Fairytale','Anthropomorphic','Hard Sci-Fi','Martial Arts','Weird Fiction','Pulp Fiction','Dark Fantasy','Apocalyptic','Prehistoric']
    },
    {name: 'Type of Armor', value: '', order: 0, heading: 'Basic', simple:true, custom: false, 
      dropDownOptions: ['Helmet','Gauntlet','Cuirass','Hauberk','Plate Carrier','Bodysuit','Greaves','Vambraces','Pauldrons','Breastplate','Chainmail','Scale Armor','Splint Armor','Lamellar Armor','Brigandine','Gambeson','Tasset','Gorget','Rerebrace','Sabatons','Plackart','Shield','Power Armor ','Energy Shield','Mail Coif','Armored Robes ','Ring Armor','Spaulders','Armored Gloves','Full Plate Armor']
    },
    {name: 'Rarity', value: '', order: 0, heading: 'Basic', simple:false, custom: false, 
      dropDownOptions: ['Abundant','Pentiful','Common','Widespread','Uncommon','Scarce','Infrequent','Rare','Very Rare','Exceptional','Extraordinary','Unique','Mythic','Fabled','Legendary','Divine','Trancendant','Cosmic','Timeless','Epic','Ultra-Rare','Mystical','Phenomenal','Singular','Exotic','Prophetic','Celestial','Metaphysical','Quantum','Dimensional']
    },
    {name: 'Armor Function', value: '', order: 0, heading: 'Basic', simple:true, custom: false, 
      dropDownOptions: ['Personal Protection','Mounted Combat','Ceremonial Use','Siege Defense','Ranged Combat','Dueling','Hunting','Underwater Exploration','Space Exploration','Intimidation Tactics','Stealth Missions','Crowd Control','Riot Control','Jungle Warfare','Arctic Warfare','Desert Warfare','Gladiatorial Combat','Parade Armor','Infantry Combat','Knightly Combat','Jousting','Religious Ceremonies','Symbol of Office','Symbol of Nobility','Guerrilla Warfare','Urban Warfare','Dungeon Exploration','Astral Travel','Dungeon Delving','High-altitude Combat']
    },
    {name: 'Appearance', value: '', order: 0, heading: 'Aesthetic and Craftsmanship', simple:false, custom: false, 
      dropDownOptions: ['Intimidating','Shining','Rugged','Sleek','Intricate','Engraved','Embossed','Minimalistic','Oversized','Lightweight','Heavy-duty','Futuristic','Traditional','Noble','Rustic','Artistic','Flamboyant','Elegantly simplistic','Ornate','Gothic','Elaborate','Streamlined','Grandiose','Unadorned','Lustrous','Matte','Majestic','Dwarven','Elven','Spartan']
    },
    {name: 'Materials', value: '', order: 0, heading: 'Aesthetic and Craftsmanship', simple:true, custom: false, 
      dropDownOptions: ['Iron','Steel','Bronze','Leather','Kevlar','Ceramics','Titanium','Carbon Fiber','Mithril','Adamantium','Obsidian','Aluminium','Elven Steel','Dwarven Iron','Spider Silk','Dragon Bone','Crystal','Moonstone','Bamboo','Rhinoceros Hide','Ethereal Cloth','Rune-Infused Wood','Enchanted Ice','Silk','Copper','Brass','Hardened Leather','Silver','Platinum','Gold']
    },
    {name: 'Craftsmanship', value: '', order: 0, heading: 'Aesthetic and Craftsmanship', simple:false, custom: false, 
      dropDownOptions: ['Exquisite','Masterful','Professional','Artisanal','Mediocre','Superior','Average','Refined','Unmatched','Impressive','Ordinary','Exceptional','Skilled','Expert','Basic','Remarkable','Supreme','Excellent','Distinguished','Precise','Unique','Sophisticated','Handmade','Industrial','Advanced','Incomparable','High-end','Cutting-edge','Innovative','Unrivaled']
    },
    {name: 'Decoration', value: '', order: 0, heading: 'Aesthetic and Craftsmanship', simple:false, custom: false, 
      dropDownOptions: ['Engravings','Filigree','Gem inlays','Painted motifs','Gold leaf','Embroidery','Heraldic symbols','Runes','Crests','Animal motifs','Relief carvings','Polychrome','Scallop shells','Floral patterns','Tassels','Fringes','Pearl embellishments','Ivory accents','Silver inlays','Feathers','Beading','Lace edging','Metallic threading','Sequins','Brocade details','Rivets','Decorative buckles','Scrollwork','Sculpted metal details','Leather embossing']
    },
    {name: 'Condition', value: '', order: 0, heading: 'Aesthetic and Craftsmanship', simple:false, custom: false, 
      dropDownOptions: ['Pristine','Scratched','Pitted','Dented','Cracked','Rusty','Tarnished','Warped','Burnished','Polished','Scuffed','Chipped','Stained','Dull','Shiny','Faded','Broken','Repaired','Gleaming','Worn','Aged','Weathered','Battered','Damaged','Neglected','Distressed','Decayed','Frayed','Corroded','Restored']
    },
    {name: 'Benefits', value: '', order: 0, heading: 'Performance and Lore', simple:true, custom: false, 
      dropDownOptions: ['Enhanced Protection','Resistance to Magic','Camouflage Abilities','Enhanced Strength','Protection from Elemental Damage','Increased Speed','Resistance to Poison','Improved Agility','Increased Endurance','Healing Abilities','Invisibility','Resistance to Psychic Attacks','Reflective Shielding','Aura of Fear to Deter Enemies','Ability to Absorb Damage','Enhanced Stealth Abilities','Reduction in Noise Made','Resistance to Status Effects ','Enhanced Perception','Ability to Cast Spells','Increased Carrying Capacity','Resistance to Physical Attacks','Increased Luck ','Ability to Fly','Intimidation of Enemies','Enhanced Charisma','Enhanced Regeneration Abilities','Teleportation','Ability to Breathe Underwater','Provides Sustenance']
    },
    {name: 'Drawbacks', value: '', order: 0, heading: 'Performance and Lore', simple:true, custom: false, 
      dropDownOptions: ['Restricted Mobility','Increased Weight','Vulnerability to Elemental Damage ','Reduced Strength','Vulnerability to Magic','Reduced Speed','Increases Noise Made','Vulnerability to Poison','Reduced Agility','Reduced Endurance','Drains Life Force or Health','Vulnerability to Psychic Attacks','Attracts Enemies','Induces Fear in the Wearer','Only Offers Limited Protection','Limited Durability','Reduces Perception ','Curses','Reduces Luck ','Creates Dependency on the Armor','Reduces Charisma','Can Only be Used by Certain Wearers','Causes Pain','Reduces Intelligence','Reduces Wisdom','May Betray the Wearer','Drains Magical Power ','Reduces Dexterity','Induces Hunger','Unexpected Side Effects']
    },
    {name: 'Origin', value: '', order: 0, heading: 'Performance and Lore', simple:false, custom: false, 
      dropDownOptions: ['Crafted by a famous smith','Forged in dragon fire','Created by an ancient civilization','Gifted by a deity','Infused with magical energy at the moment of creation','Recovered from a sunken ship','Found in the ruins of a fallen kingdom','Created by an alchemist experimenting with unique materials','Made by a blacksmith under the guidance of a ghost','Crafted during a lunar eclipse, harnessing its energy','Forged in the heart of a volcano','Originates from a different dimension','Crafted from materials found in a meteorite','Passed down through generations of a noble family','Forged from the remains of a defeated enemy','Born out of a pact with a demon','Crafted by dwarves in their mountain forges','Awarded as part of a tournament prize','Blessed by a saint or a spiritual leader','Gifted by fey beings from an enchanted forest','Crafted from the bones of ancient beasts','Found in the den of a powerful creature','A relic from a long-lost era','Forged from the elements during a massive storm','Created from the remnants of a fallen star','Fashioned by elves using their ancestral knowledge','Crafted by a blind monk, guided by divine intuition','Designed by an inventor as a personal project','Infused with the soul of a brave hero','Recovered from an extraterrestrial crash site']
    },
    {name: 'Famous Wearers', value: '', order: 0, heading: 'Performance and Lore', simple:true, custom: false, 
      dropDownOptions: ['A notorious warlord','A legendary queen','A renowned gladiator','A mythical hero','A revered paladin','A powerful sorcerer','A notorious pirate captain','A courageous knight','A fabled assassin','A charismatic bard','An eccentric explorer','A prodigious blacksmith','A cunning rogue','A fierce tribal chieftain','A celebrated archer','A famed dragon slayer','A renowned guild master','A notorious mercenary','A legendary king','A respected samurai','A celebrated champion of the realm','A famous Spartan warrior','A well-known Viking raider','A revered prophet','A fabled ranger','A notorious bandit king','A heroic adventurer','An acclaimed strategist and general','A warrior who is a legend among his people','A well-respected defender of the city.']
    },
    {name: 'Cultural Significance', value: '', order: 0, heading: 'Performance and Lore', simple:false, custom: false, 
      dropDownOptions: ['A symbol of heroism and bravery','An emblem of nobility and prestige','A signifier of a warrior\'s status or rank','An object of reverence in religious rituals','A memento from a historical battle or war','An heirloom passed down through generations','A mark of a particular guild or faction','An artifact related to a cultural myth or legend','A traditional wear for specific ceremonial events','A mark of endurance or survival','An indicator of a wearer\'s wealth or social standing','An emblem of protection or defense','A cultural symbol of unity or solidarity','An indicator of a person\'s occupation or trade','A ceremonial gift for a coming-of-age ceremony','A symbol of a kingdom or empire\'s power and might','An object believed to bring luck or ward off evil','A symbol of honor and valor in battle','An emblem of a specific historical period or era','A cultural symbol of freedom or liberation','An object associated with an important historical figure','A symbol of sacrifice or martyrdom','A mark of justice or law enforcement','An artifact associated with a specific cultural prophecy','A signifier of a rite of passage or initiation','A symbol of hope during times of war or strife','A cultural sign of peace or truce','A ceremonial armor used in royal coronations','An artifact believed to have magical or spiritual properties','A symbol of a tribe or clan\'s lineage or ancestry']
    },
    {name: 'Stories and Legends', value: '', order: 0, heading: 'Performance and Lore', simple:true, custom: false, 
      dropDownOptions: ['Worn during a critical battle by a legendary knight.','Crafted by a deity according to ancient texts.','Rumored to possess indestructibility.','Once belonged to a fallen king, if whispers are to be believed.','Grants invincibility to its wearer, so the tales say.','Forged in dragonfire, as per the ancient legends.','Worn by a notorious pirate captain during his reign.','Known to bring good fortune in battle.','Used by a queen in disguise during a notorious rebellion.','Enchanted by a powerful wizard, giving it unique abilities.','Worn by a heroic outlaw during his daring escapades.','Created by an extinct civilization, displaying their advanced craftsmanship.','Guided its wearer through a deadly labyrinth, as per the myth.','Changes its shape according to the wearer\'s need, if the stories are true.','The prize in a grand tournament, as recorded in the chronicles.','A key to a hidden realm, according to folklore.','Makes the wearer invisible at will, so the rumors go.','Stolen from a tyrant by a cunning thief during a dark era.','Part of a legendary prophecy foretelling great events.','Saved a city from destruction by its miraculous properties.','Cursed, bringing doom to its wearer, as per the cautionary tales.','Discovered in a mysterious, ancient tomb.','Used by a spy to infiltrate an enemy kingdom, leading to a crucial victory.','Allows the wearer to speak with the spirits, according to spiritualists.','Increases the wearer\'s strength tenfold, if the legends are true.','Lost in a massive siege and later recovered, causing great celebration.','The last work of a legendary blacksmith, a testament to their skill.','Grants the wearer the ability to see the future, as the myth goes.','Worn by a hero who single-handedly defeated a monstrous beast.','The cause of a major war between rival kingdoms, as per historical accounts.']
    },
    {name: 'Additional Details', value: '', order: 0, heading: 'Background', custom: false},
    {name: 'Setting (user generated)', value: '', order: 0, simple:true, heading: 'Background', custom: false, dropDownOptions: [], specialDropdown: true, specialDropdownType: 'Narrative', displayOutsideAccordian:true, attachedObject: {}},
    {name: 'Within Location (user generated)', value: '', displayOutsideAccordian:true, order: 0, simple:true, heading: 'Background', custom: false, dropDownOptions: [], specialDropdown: true, specialDropdownType: 'Location', attachedObject: {}},
  ];
