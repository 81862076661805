import React from 'react';

const Accordion = ({ label, children, isOpen, onClick }) => {
  return (
    <div className="accordion">
      <div className="accordion-label" onClick={onClick}>
        <span>{label}</span>
        <button className={`toggle-button ${isOpen ? 'open' : 'closed'}`}>
          {isOpen ? '–' : '+'}
        </button>
      </div>
      {isOpen && <div className="accordion-content">{children}</div>}
    </div>
  );
};

export default Accordion;