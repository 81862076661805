import React, { useState, useEffect } from 'react';
import { FaRedoAlt } from 'react-icons/fa';

const LifePathDisplay = ({
  lifePath = "",
  lifePathStage = 1,
  userChoices = {},
  regen
}) => {
  return (
    <div className="lifePath">
      {lifePath ? (
        Object.keys(lifePath).map(stageKey => {
          // Extract the stage number from the stageKey (e.g., 'LifePathStage1' => 1)
          const stageNumber = stageKey.match(/\d+$/)[0];
          const userChoiceKey = `lifePathStage_answer_${stageNumber}`;
          const userChoice = userChoices[userChoiceKey] || false;

          return (
            <div className="character-paragraph" key={stageKey}>
              <h3>{stageKey}</h3>
              {lifePathStage !== 7 && regen && ( 
                <FaRedoAlt onClick={(e) => regen(e, stageNumber)} className="regenerateButton description-refresh"/>
              )}
              <div>{lifePath[stageKey].Narrative}</div>
              {userChoice && (
                <>
                  <h4>Choice {stageNumber}</h4>
                  <div>{userChoice}</div>
                </>
              )}
              <br/>
            </div>
          );
        })
      ) : (
        <div>No life path data available.</div>
      )}
    </div>
  );
};

export default LifePathDisplay;
