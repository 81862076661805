import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import './LoginModal.css';
import Sales from '../Sections/Sales.js';
import { FaCheck, FaTimes, FaPlus } from 'react-icons/fa';

const LoginModal = ({ showModalFromParent, toggleLoginModal, message = '', toggleUpsellVisible }) => {
  const [showModal, setShowModal] = useState(showModalFromParent);
  const [mode, setMode] = useState('register');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [loggedIn, setLoggedIn] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [genericMessage, setGenericMessage] = useState(message);

  const [showUpsellButton, setShowUpsellButton] = useState(false);

  

  useEffect(() => {
    setShowModal(showModalFromParent);
  }, [showModalFromParent]);
  useEffect(() => {
    if(message.length > 0)
      setGenericMessage(message);
  }, [message]);

useEffect(() => {
    if(genericMessage === 'Token limit reached' || genericMessage === 'You must be a Plus member to generate conversation images'){
      setShowUpsellButton(true);
      localStorage.setItem('isPlusMember', 'false');
      //if(loggedIn)
        //toggleUpsellVisible();
    }
  }, [genericMessage, showModal]);



  useEffect(() => {
  const handleStorageChange = (e) => {
    if (e.key === 'token') {
      setLoggedIn(!!localStorage.getItem('token'));
    }
  };
  if (window.location.href.indexOf('welcome?relogin') > -1) {
  let url = new URL(window.location);
  let searchParams = new URLSearchParams(url.search);
  searchParams.delete('relogin');
  url.search = searchParams.toString();
  window.history.pushState({}, '', url);
  handleLogin(false, true);
}

  // Add an interceptor to handle expired tokens
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      // Check if the error is a 403 Forbidden, which usually indicates an expired JWT
      if (error.response && error.response.status === 403) {
        // Remove the token from local storage and update the loggedIn state
        localStorage.removeItem('token');
        localStorage.removeItem('username');
        setLoggedIn(false);
      }
      return Promise.reject(error);
    }
  );

  window.addEventListener('storage', handleStorageChange);
  const token = localStorage.getItem('token');
  if (token) {
    setLoggedIn(true);
  }

  return () => {
    window.removeEventListener('storage', handleStorageChange);
  };
}, []);

  const handleLogin = async (event = false, refresh = false) => {
    console.log('logging in...');
    setGenericMessage('');
    setError('');
    if(event)
      event.preventDefault();
    try {
      let response = false;
      if(!refresh){
        response = await axios.post(
          'https://accounts.charactercomposer.com/wp-json/api/v1/token',
          {
            username,
            password,
          }
        );
      }
      if (refresh || response.data.jwt_token) {
        if(!refresh){
          localStorage.setItem('username', username);
          localStorage.setItem('token', response.data.jwt_token);
          localStorage.removeItem('tokenReset');
          localStorage.removeItem('tokenCount');
          setLoggedIn(true); // Add this line
          const authString = btoa(`${username}:${password}`);
        }
        try{
          console.log('getting meta...');
                var url = window.location.href;
                var apiUrl = 'http://localhost:3001/user-meta-data/';
                if (url.indexOf('charactercomposer.com') > -1) {
                  apiUrl = 'https://charactercomposer.com/user-meta-data/';
                }
                let token = localStorage.getItem('token');
                 // Call to new endpoint to get meta data
                const customerMeta = await axios.post(
                  apiUrl,
                  {},
                  {
                    headers: {
                      'Content-Type': 'application/json',
                      'API-Key': "2(yY62.mG#6UI324324",
                      'Authorization': `Bearer ${token}`,
                    }
                  }
                );
                if (customerMeta.data.success) {
                  if(customerMeta?.data?.customerMeta?.meta?.stripeCustomerId)
                    localStorage.setItem('stripeCustomerId', customerMeta.data.customerMeta.meta.stripeCustomerId);
                  if(customerMeta?.data?.customerMeta?.roles[0] == 'customer')
                    localStorage.setItem('isPlusMember', 'true');
                  else
                    localStorage.setItem('isPlusMember', 'false');
                } else {
                  console.log("Failed to fetch Stripe Customer ID.");
                }
                setSuccessMessage('Time to create something cool...'); // Add this line
                await setTimeout(() => {
                  closeModal();
                  setSuccessMessage('');
                  //window.location.reload();
                }, 2500);
        } catch(error){
          console.log(error);
        }
        
      }
    } catch (err) {
      setError('Invalid credentials. Please try again.');
    }
  };

  const handleRegistration = async (event) => {
  event.preventDefault();
  setError('');
  if (password !== confirmPassword) {
    setError('Passwords do not match. Please try again.');
    return;
  }
  try {
    var url = window.location.href;
    var apiUrl = 'http://localhost:3001/signup/';
    if (url.indexOf('charactercomposer.com') > -1) {
      apiUrl = 'https://charactercomposer.com/signup/';
    }

    const response = await axios.post(apiUrl, {
      email,
      password,
    }, {
      headers: {
        'Content-Type': 'application/json',
        'API-Key': '2(yY62.mG#6UI324324'
      }
    });




    if (response.status === 201) {
      setError(null);

      // Request a token for the new user
      const tokenResponse = await axios.post(
        'https://accounts.charactercomposer.com/wp-json/api/v1/token',
        {
          username: email,
          password,
        }
      );



      // Save the token in local storage, update loggedIn state, and hide the modal
      if (tokenResponse.data.jwt_token) {
        localStorage.setItem('username', email);
        localStorage.setItem('token', tokenResponse.data.jwt_token);
        localStorage.removeItem('tokenCount');
        setLoggedIn(true);
        setGenericMessage('');
        setSuccessMessage('Welcome to Character Composer'); // Add this line
        await setTimeout(() => {
          setSuccessMessage('');
          window.location.href = "/welcome";
          closeModal();
        }, 2500);
      } else {
        setError('Error logging in. Please try again.');
      }
    }
  } catch (err) {
    setError('Error registering. Please try again.');
  }
};
  

  const handleClickOutside = (e) => {
  if (!e.target.closest(".modal-content")) {
    closeModal();
  }
};
const upgradeClickHandler = async () => {
  await closeModal();
  toggleUpsellVisible(true);
}
  
  const closeModal = async () => {
    console.log('closing');
    toggleUpsellVisible(false);
    setMode("register");
    toggleLoginModal();
    await setTimeout(function(){},200);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    localStorage.removeItem('currentCharacter');
    localStorage.removeItem('viewType');
    localStorage.removeItem('customFields');
    localStorage.removeItem('stripeCustomerId');
    localStorage.removeItem('isPlusMember');
    
    window.location.href = "/";
  };

  function handleDashboardClick() {
    window.location.href = "/characters/";
}

function timeUntilTokenReset() {
    const tokenResetTime = new Date(localStorage.getItem('tokenReset'));

    const now = new Date();
    const differenceInMillis = tokenResetTime - now;

    if (differenceInMillis <= 0) {
        return "now";
    }

    const differenceInMinutes = Math.ceil(differenceInMillis / (1000 * 60));

    if (differenceInMinutes < 60) {
        return differenceInMinutes + " minute" + (differenceInMinutes > 1 ? "s" : "");
    } else {
        const differenceInHours = Math.ceil(differenceInMinutes / 60);
        return differenceInHours + " hour" + (differenceInHours > 1 ? "s" : "");
    }
}
const location = useLocation();
const isNotOnCharactersPage = location.pathname !== '/characters' && location.pathname !== '/characters/';
const hasIdParam = new URLSearchParams(location.search).has('id');


  return (
    <div className="login-button-container">
    

    {location.pathname.indexOf('/chat' ) < 0 && !hasIdParam && (
      <>
      <a href="/chat" className="login-button">
          Chat
      </a>
          &nbsp;&nbsp;
          </>
    )}
    {location.pathname !== '/'   && (
      <>
      <a href="/" className="login-button">
          Create
      </a>
          &nbsp;&nbsp;
          </>
    )}


    {(isNotOnCharactersPage || hasIdParam) && (
    <>
      <a href="/characters" className="login-button">
        View
      </a>
      &nbsp;&nbsp;
      </>
    )}
    

      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <button className="close" onClick={closeModal}>
              <FaTimes/>
            </button>
            {genericMessage && <p className="generic-message">{genericMessage}</p>}
            {successMessage && <h1 className="success-message">{successMessage}</h1>}
            {showUpsellButton && (
              <div className="token-reset-message">
                <p>
                  {loggedIn ? "Get Plus to unlock unlimited tokens and more!" : "Create a free account to unlock higher token limits and more!"}
                </p>
                {loggedIn ? 
                  <>
                    <button onClick={upgradeClickHandler} className="upgradeToPlus">Upgrade to a <b>Plus</b> account</button> 
                    <button onClick={closeModal} className="continueWithFree">Continue with free account</button> 
                  </>
                  : ""
                }
                {loggedIn ? <div className="smallPrint">Free tokens refresh every 72 hours...</div> : ""}
              </div>
            )}
            {error && <p className="error-message">{error}</p>}
            {loggedIn ? (
              <div className="upgrade-message">
                {!genericMessage && (<><h2>Success!</h2><FaCheck className="bigGreenTick"/></>)}
                
              </div>
            ) : (
              <>
              {mode === 'login' ? (
                
                <form onSubmit={handleLogin}>
                  <h2>Login</h2>
                  <input
                    type="text"
                    placeholder="Email"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                  <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <button className="ctaButton big" type="submit">
                    Log in
                  </button><br/>
                  <a
                    className="forgot-password-button"
                    href="https://accounts.charactercomposer.com/wp-login.php?action=lostpassword"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Forgot Password
                  </a>
                  <p>
                    <b>Or</b>{' '}<br/>
                    <a href="#" className="ctaButton small" onClick={() => setMode('register')}>
                      Create Account
                    </a>
                  </p>
                  
                </form>
              ) : (
                <form onSubmit={handleRegistration}>
                  <h2>Account Creation</h2>
                  <input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <input
                    type="password"
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                  <button className="ctaButton big" type="submit">
                    Create Account
                  </button>
                  <p>
                    <b>Or</b>{' '}<br/>
                    <a href="#" className="ctaButton small" onClick={() => setMode('login')}>
                      Login to existing account
                    </a>
                  </p>
                </form>
              )}
            </>
          )}
          </div>
        </div>
      )}
    </div>
  );
};

export default LoginModal;