import React, { useState, useEffect, useRef } from 'react';
import { FaRedoAlt, FaShare, FaHeart, FaAngleUp, FaAngleDown } from 'react-icons/fa';
import ImageComponent from './ImageComponent.js';
import LifePathDisplay from './LifePathDisplay.js';
import LoadingBox from './LoadingBox.js';

const LifePathComponent = ({
  lifePath = "",
  lifePathInputs = {},
  userChoices = {},
  handleLifePath,
  imageRefreshSubmit
}) => {
  const [customOption, setCustomOption] = useState('');
  const [lifePathStage, setLifePathStage] = useState(1); 
  const [imageUrl, setImageUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingOptions, setIsLoadingOptions] = useState(false);
  const [userHasTyped, setUserHasTyped] = useState(false);
  const textareaRef = useRef(null);

  useEffect(() => {
      if(!customOption || customOption.length == 0)
        return;
        // Assume lifePathStage is the current stage like "Life Path Stage 1"
        const currentStage = lifePath[`Life Path Stage ${lifePathStage}`];
        if(!currentStage)
          return;
        // Extract only the options from the current stage
        const options = Object.keys(currentStage).filter(key => key.startsWith("Option"));
        // Check if customOption matches any of the options
        const isCustomOptionUnique = !options.some(optionKey => currentStage[optionKey] === customOption);
        // Set userHasTyped to true if customOption is unique
        if (isCustomOptionUnique) {
            setUserHasTyped(true);
        } else {
            setUserHasTyped(false);
        }

  }, [customOption, lifePath, lifePathStage]);

  useEffect(() => {
    // Determine the current stage based on the keys in lifePath
    const stages = Object.keys(lifePath)
      .map(key => parseInt(key.match(/\d+$/)?.[0] || 0))
      .filter(stageNumber => !isNaN(stageNumber));
    
    if (stages.length > 0) {
      const maxStage = Math.max(...stages);
      if(localStorage.getItem('lifePathStage') == 7)
        setLifePathStage(7);
      else
        setLifePathStage(maxStage);
      console.log('stage is: ' + maxStage);
    }
  }, [lifePath]);

const handleOptionClick = async (e, option) => {
  e.preventDefault();
  e.stopPropagation();
  setCustomOption(option);
  setUserHasTyped(false);
  setTimeout(() => {
    if (textareaRef.current) {
      const rect = textareaRef.current.getBoundingClientRect();
      const isVisible = rect.top >= 0 && rect.bottom <= window.innerHeight;

      // Only scroll if the textarea is not fully visible
      if (!isVisible) {
        textareaRef.current.scrollIntoView({ behavior: 'smooth' });
        setTimeout(() => {
          focusAndSetCursor(textareaRef.current);
        }, 360); // Adjust this time as needed
      } else {
        // If textarea is already visible, just focus and set cursor
        focusAndSetCursor(textareaRef.current);
      }
    }
  }, 10);
};

const focusAndSetCursor = (textarea) => {
  textarea.focus();
  const length = textarea.value.length;
  textarea.setSelectionRange(length, length);
};


  const handleFinishClick = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setLifePathStage(7);
    localStorage.setItem('lifePathStage', 7);
    var currentCharacter = JSON.parse(localStorage.getItem('currentCharacter'));
    setIsLoading(true);
    await imageRefreshSubmit(false,false,currentCharacter, false,false, true, true);
    setIsLoading(false);
  };

  const handleCustomOptionChange = (event) => {
    setCustomOption(event.target.value);
  };

  const handleCustomOptionSubmit = async (e) => {
    const updatedChoices = {
      ...userChoices,
      [`lifePathStage_answer_${lifePathStage}`]: customOption
    };
    setLifePathStage(lifePathStage + 1);
    setCustomOption("");
    setUserHasTyped(false);
    setIsLoading(true);
    await handleLifePath( e,lifePath, lifePathStage + 1, updatedChoices, lifePathInputs);
    setIsLoading(false);
  };

  const renderLifePathInputs = () => {
    return (
      <div className="lifePathInputsGrid">
        {Object.entries(lifePathInputs).map(([key, value]) => (
          <div key={key} className="lifePathInput">
            <strong>{key}:</strong> {value}
          </div>
        ))}
      </div>
    );
  };

  const renderLifePathOptions = () => {
  // Check if lifePath is initialized
  if (!lifePath || isLoading) {
    return <div></div>;
  }


  const currentStageKey = `Life Path Stage ${lifePathStage}`;

  const handleRegenOptions = async (event) => {
    console.log('user has typed');
    console.log(userHasTyped);
    setIsLoadingOptions(true);
    var useOptionPrompt = false;
    if(userHasTyped)
      useOptionPrompt = customOption;
    await handleLifePath(event, lifePath, lifePathStage, false,lifePathInputs, false, useOptionPrompt );
    setIsLoadingOptions(false);
  };

  return (
    <div className="lifePathOptions">
        <div className="lifePathCustomOption">
          <textarea ref={textareaRef} className="customChoiceInput" value={customOption} onChange={handleCustomOptionChange} placeholder={`What does ${lifePathInputs.Name} do next? \n\nWrite your own decision or choose from the options below...`}></textarea>
          {!isLoadingOptions && (
            <div className="decision-submit-flexbox">
                  <button className="lifePathOptionButton more" onClick={(event) => handleRegenOptions(event)}>
                    <FaRedoAlt className="textDice" /> Regenerate
                  </button>
                  <button key="custom" onClick={handleCustomOptionSubmit} className="lifePathOptionButton custom">
                    Submit Decision
                  </button>
            </div>
          )}
        </div>
      {
        lifePath && !isLoadingOptions && lifePath[currentStageKey] && (
          <div className="lifePathOptionsContainer">
            {Object.entries(lifePath[currentStageKey])
              .filter(([key, _]) => key.startsWith('Option'))
              .filter(([key, text]) => text.length > 0)
              .map(([key, text]) => (
                <button key={key} onClick={(e) => handleOptionClick(e, text)} className="lifePathOptionButton">
                  {text}
                </button>
              ))
            }
          </div>
        )
      }
 
          <div className="regenTip">
            Prompt choices given by writing in the Life Path Descision text box then click <b>Regnerate</b>
          </div>

    </div>
  );
};

const handleRegenerateClick = async (e, stage) => {
    e.preventDefault();
    localStorage.setItem('lifePathStage', false);
    console.log('Regenerating stage ' + stage);
    stage = parseInt(stage);
    // Remove all life path stages after the specified stage
    Object.keys(lifePath).forEach(key => {
        const keyStage = parseInt(key.replace(/\D/g, ''));
        if (keyStage >= stage) {
            delete lifePath[key];
        }
    });
    // Remove all user choices corresponding to the stages after the current stage
    Object.keys(userChoices).forEach(key => {
        const keyStage = parseInt(key.split('_')[2]); // Assuming the format is 'lifePathStage_answer_{stageNumber}'
        console.log('deletiung answers from stage: ' + keyStage);
        if (keyStage >= stage) {
            delete userChoices[key];
        }
    });
    setCustomOption("");
    setIsLoading(true);
    // Call the function to handle life path (assuming this function updates the lifePath object or state)
    await handleLifePath(e, lifePath, stage, userChoices, lifePathInputs, true);
    setIsLoading(false);
    // Update the current life path stage
    setLifePathStage(stage);
};



  return (
    <div className="lifePath">
      <h2>{lifePathInputs.Name}'s Life Path</h2>
      {lifePathStage !== 7 && renderLifePathInputs()}
      <LifePathDisplay lifePathStage={lifePathStage} userChoices={userChoices} lifePath={lifePath} regen={handleRegenerateClick}/>
  
      {lifePathStage < 6 && localStorage.getItem('lifePathStage') !== 'conclude' && (
      <div className="lifePathOptionsSection">
        { !isLoading && (
          <h3>Life Path Decision</h3>
        )}
        <div>
          {renderLifePathOptions()}
        </div>
      </div>
      )}
      { (lifePathStage == 6 || localStorage.getItem('lifePathStage') == 'conclude') && !isLoading && (
      <>
        <button className="finishLifePath" onClick={(e) => handleFinishClick(e)} >Finalize Life Path</button>
      </>
      )}
      { (isLoading || isLoadingOptions) && (
      <>
        <LoadingBox loading={isLoading || isLoadingOptions} show_tips={false}/>
      </>
      )}
    </div>
  );
};

export default LifePathComponent;
