import React from 'react';

const Eula = ({}) => {
  return (
    <div className="terms-container">
      <div className="terms-and-conditions">
        <h1>End-User License Agreement</h1>
            <p><strong>IMPORTANT:</strong> PLEASE READ THIS END-USER LICENSE AGREEMENT (“EULA”) CAREFULLY BEFORE USING Charactercomposer.com. BY ACCESSING OR USING THE SERVICES, YOU AGREE TO BE BOUND BY THE TERMS OF THIS EULA.</p>

            <h2>1. Grant Of License</h2>
            <p>Charactercomposer.com grants you a non-exclusive, non-transferable, revocable license to access and use the services provided on Charactercomposer.com, subject to the terms and conditions of this EULA.</p>

            <h2>2. Use Of Services</h2>
            <p>You may use the provided services in accordance with this EULA.</p>

            <h2>3. Restrictions</h2>
            <p>You shall not:</p>
            <ul>
                <li>Modify, reverse engineer, or create derivative works of the services.</li>
                <li>Use the services for any illegal or unauthorized purpose.</li>
                <li>Violate any laws, regulations, or third-party rights.</li>
            </ul>

            <h2>4. Paid Services</h2>
            <p>Some features may require payment. You agree to pay all applicable fees for those services.</p>

            <h2>5. User-Generated Content</h2>
            <p>You grant Charactercomposer.com a license to use, modify, and distribute your content for promotional purposes. You are responsible for ensuring your content complies with all applicable laws and does not infringe on any third-party rights.</p>

            <h2>6. Termination</h2>
            <p>This EULA is effective until terminated. Charactercomposer.com may terminate this EULA at any time if you violate its terms.</p>

            <h2>7. Disclaimer Of Warranties</h2>
            <p>The services are provided "AS IS" without warranties of any kind, express or implied.</p>

            <h2>8. Limitation Of Liabilities</h2>
            <p>Charactercomposer.com shall not be liable for any indirect, incidental, or consequential damages arising out of or related to this EULA.</p>

            <h2>9. Governing Law</h2>
            <p>This EULA shall be governed by the laws of the United Kingdom.</p>

            <h2>10. Acceptance</h2>
            <p>By using the services, you agree to be bound by the terms of this EULA.</p>

            <h2>11. Changes To This EULA</h2>
            <p>Charactercomposer.com may modify this EULA at any time. Changes will be posted on Charactercomposer.com, and your continued use of the services constitutes acceptance of those changes.</p>
        </div>

    </div>
  );
};

export default Eula;
