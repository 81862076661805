import React from 'react';

const Terms = ({}) => {
  return (
    <div className="terms-container">
      <div className="terms-and-conditions">
        <h1>Terms of Service</h1>
            <p><strong>Last Updated:</strong> 01/11/2023</p>

            <h2>Introduction/Overview</h2>
            <p>Welcome to CharacterComposer.com. This document outlines the terms and conditions governing your use of our website and the services provided herein.</p>

            <h2>Acceptance of the Terms</h2>
            <p>By accessing and using CharacterComposer.com, you agree to comply with and be bound by these Terms of Service. If you do not agree, you may not use our website.</p>

            <h2>Changes to the Terms</h2>
            <p>We reserve the right to modify, add to, or delete portions of these terms at our discretion. Users will be notified of significant changes through announcements on the website or via email.</p>

            <h2>Account Registration and Security</h2>
            <p>Users may need to create an account to access specific features. Please provide accurate and up-to-date information during account registration. You are responsible for maintaining the confidentiality of your password and account.</p>

            <h2>User Conduct and Responsibilities</h2>
            <p>Users must abide by all applicable local and international laws. Engaging in harassment, spamming, or any illegal activities is prohibited. Creating or sharing offensive, sexual, hateful, or illegal content may result in account termination.</p>

            <h2>Intellectual Property Rights</h2>
            <p>All content, logos, and trademarks on CharacterComposer.com are our property. Users retain rights to content they upload or share but grant us non-exclusive rights to use and reproduce such content for purposes such as use in screenshots to promote the website.</p>

            <h2>Privacy Policy Reference</h2>
            <p>Please refer to our Privacy Policy for information on data collection and protection.</p>

            <h2>Termination</h2>
            <p>We reserve the right to terminate user accounts at our discretion. We may discontinue any service or feature without notice.</p>

            <h2>Limitations of Liability and Disclaimer of Warranties</h2>
            <p>CharacterComposer.com is provided "as is" without warranties of any kind. We do not accept liability for losses or damages arising from your use of the site.</p>

            <h2>Payment and Fees</h2>
            <p>Some services or features may require payment. Payment methods, refund policies, and other financial details can be found on our Payments Page.</p>

            <h2>Third-Party Links and Services</h2>
            <p>We are not responsible for third-party content or services accessed through our website.</p>

            <h2>Contact Information</h2>
            <p>For questions or concerns, contact us on Discord or email us at <a href="mailto:support@charactercomposer.com">support@charactercomposer.com</a></p>
        </div>

    </div>
  );
};

export default Terms;
