import React, { useState, useEffect } from 'react';
import MyCarousel from '../Composer/CarouselReact.js';

const handleGetCharacters = async () => {
  var url = window.location.href;
  var apiUrl = 'http://localhost:3001/characters_endpoint/';
  if (url.indexOf('charactercomposer.com') > -1)
    apiUrl = 'https://charactercomposer.com/characters_endpoint/';
  const res = await fetch(apiUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'API-Key': "2(yY62.mG#6UI324324"
    },
    body:  JSON.stringify({ limit:8 }),
  });

  const result = await res.json();

  return result;
};

function getBaseURL() {
  var url = window.location.href;
  var apiUrl = 'http://localhost:3001';
    if(url.indexOf('charactercomposer.com') > -1)
       apiUrl = 'https://charactercomposer.com';
  return apiUrl;
}


const RecentCreations = () => {
  const [characters, setCharacters] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const data = await handleGetCharacters();
        setCharacters(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    })();
  }, []);

   var slides = Array.isArray(characters)
  ? characters
  .filter(character => character.imageUrl)
  .map((character) => ({
      image: getBaseURL()  + '/saved/images/' + character.imageUrl,
      title: character.formValues.Name,
      description: character.description,
      attributes: character.formValues,
      mode: character.mode,
      simpleMode: character.simpleMode
    }))
  : [];

  return (
    <div className="recent-creations section" >
      <div className="coming-soon" onClick={() => window.location.href='/characters'} >
        <h2>Recent Creations <a id="see-more-inline" href="/characters">
          - See all and chat
        </a></h2>

        <MyCarousel slides={slides}  />
        <a id="see-more-characters" href="/characters">
          See more creations and chat
        </a>
      </div>
    </div>
  );
};
export default RecentCreations;
