import React, { useState, useEffect, useRef } from 'react';
import { FaDiceFive } from 'react-icons/fa';
const FormSection = ({ onRandomClick, label, name, options = [], value, customValue, isSimple = false, changeParentValue, CheckNumberOfInputsFilled, attributeSorting, attributeDefault }) => {
  const [selectedValue, setSelectedValue] = useState(value || '');
  const [dynamicOptions, setDynamicOptions] = useState(options);
  const [showCustomInput, setShowCustomInput] = useState(false);
  const [customInputValue, setCustomInputValue] = useState(customValue || '');
  const customInputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [originalOptions, setOriginalOptions] = useState(options);

   useEffect(() => {
    setOriginalOptions(options);
  }, [options]);

     useEffect(() => {
    if (attributeSorting === 'Alphabetical') {
      const sortedOptions = [...dynamicOptions].sort((a, b) => a.localeCompare(b));
      if (JSON.stringify(sortedOptions) !== JSON.stringify(dynamicOptions)) {
        setDynamicOptions(sortedOptions);
      }
    } else {
      // Revert to the original order when it's not alphabetical
      if (JSON.stringify(originalOptions) !== JSON.stringify(dynamicOptions)) {
        setDynamicOptions(originalOptions);
      }
    }
  }, [attributeSorting]);



  useEffect(() => {
    setSelectedValue(value || '');
  }, [value]);

  useEffect(() => {
    if (value === '' && selectedValue !== '') {
      changeParentValue(label, '');
    } else if (value !== 'custom' && !dynamicOptions.includes(value)) {
      setDynamicOptions([...dynamicOptions, value]);
    }
  }, [value, dynamicOptions]);

  useEffect(() => {
    if (showCustomInput && customInputRef.current) {
      setTimeout(() => {
        customInputRef.current.focus();
      },50);
    }
  }, [showCustomInput]);

  const isCustomSelected = selectedValue === 'custom' || (customValue !== undefined && customValue !== '');

  const handleSelectChange = (event) => {
    const newValue = event.target.value;
    setSelectedValue(newValue);
    setShowCustomInput(newValue === 'custom');
    const customEvent = { ...event, target: { ...event.target, value: newValue } };
    changeParentValue(label, newValue);
    CheckNumberOfInputsFilled(event);
  };

  const handleCustomInputChange = (event) => {
      const newCustomValue = event.target.value;
      setCustomInputValue(newCustomValue);
  };

  const handleCustomInputBlur = () => {
    setTimeout(() => {
      if(customInputValue !== '' || selectedValue !== 'custom' || selectedValue === ''){
        changeParentValue(label, customInputValue);
        setShowCustomInput(false);
      }
    },60);
  };

  const handleGetRandom = async (event) => {
    setIsLoading(true); // Set isLoading to true before calling onRandomClick
    var new_value = await onRandomClick(event, label);
    setIsLoading(false); // Set isLoading back to false after onRandomClick completes
    setSelectedValue(new_value);
    // Trigger the onChange event to notify the parent component
    changeParentValue(label, new_value);
  };

  const handleKeyPress = (e) => {
  if (e.key === "Enter") {
    e.preventDefault();
  }
};

  return (
    <div className='form-section'>
      <div>
        <label className="form-label" htmlFor={name} onClick={handleGetRandom}>
          {label}
          <FaDiceFive
              className="input-dice"
          />
        </label>
        <select
          className={`form-select ${value ? 'filled' : ''} ${isLoading ? 'loading' : ''} ${selectedValue === '' ? 'empty' : ''}`}
          id={name}
          value={attributeDefault === 'None' &&  selectedValue == '' ? 'None' : selectedValue}
          name={name}
          onChange={handleSelectChange}
          key={JSON.stringify(dynamicOptions)}
          disabled={isLoading}
        >
          <option value={attributeDefault === 'None' ? 'None' : ''}>
            {attributeDefault === 'None' ? 'None' : 'Generate field...'}
          </option>
          <option value="custom" >
            Enter your own value
          </option>
          <option value={attributeDefault === 'None' ? '' : 'None'}>
            {attributeDefault === 'None' ? 'Generate field...' : 'None'}
          </option>
          {dynamicOptions.map((option, index) => (
            option !== "" ? (
              <option key={index} value={option}>
                {option}
              </option>
            ) : null
          ))}
        </select>
        {isLoading && (
            <div className="generating-text">Generating relevant result...</div>
          )}
      </div>
      {(showCustomInput || isCustomSelected) && (
        <div>
          <input
            className={`form-input custom-input ${value ? 'filled' : ''}`}
            type="text"
            id={`custom-${name}`}
            placeholder={`Enter custom ${label}`}
            value={customInputValue}
            onChange={handleCustomInputChange}
            onBlur={handleCustomInputBlur}
            ref={customInputRef}
            onKeyPress={handleKeyPress}
          />
        </div>
      )}
    </div>
  );
};

export default FormSection;
