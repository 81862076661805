export const FieldsLocationWorld = [
    {name: 'Name', value: '', order: 0, simple:true, heading: '', custom: false},
    {name: 'Genre', value: '', order: 0, simple:true, heading: 'Basic', custom: false, 
      dropDownOptions: ['Fantasy','Steampunk','Science-Fiction','Space Opera','Cyberpunk','Post-Apocalyptic','Modern Day','Wild West','Lovecraftian','Folklore','Superhero','Supernatural','Historical','Magical Realism','Dystopian','Alternate History','Speculative Fiction','Detective','Toyland','Spiritual','Urban Fantasy','Space Western','Biopunk','Solarpunk','Dieselpunk','Mythology','Fairytale','Anthropomorphic','Hard Sci-Fi','Martial Arts','Weird Fiction','Pulp Fiction','Dark Fantasy','Apocalyptic','Prehistoric']
    },
    {name: 'Scale and Scope', value: '', order: 0, simple:true, heading: 'Basic', custom: false, 
      dropDownOptions: ["Continent", "Multiple Continents", "Planet", "Binary Planetary System", "Solar System", "Multiple Solar Systems", "Star Cluster", "Galaxy", "Multiple Galaxies", "Galaxy Cluster", "Supercluster", "Local Group", "Intergalactic Region", "Universe", "Multiverse", "Dimension", "Cosmic Region", "Astral Plane", "Alternate Reality", "Cosmic Web", "Extra-Dimensional Space", "Interdimensional Expanse", "Cosmic Scale", "Galactic Scale", "Planetary System", "Stellar System", "Interstellar Space", "Extragalactic Space", "Sub-Galactic Region", "Cosmological Scale"]
    },
    {name: 'Status Quo and Historical Context', value: '', order: 0, simple:true, heading: 'Basic', custom: false, 
      dropDownOptions: ["Long-Standing Peace After Historic Conflicts", "Recent Unification of Formerly Warring States", "Age of Enlightenment and Intellectual Growth", "Era of Prosperity and Economic Boom", "Post-Revolutionary Reconstruction Period", "Golden Age of Artistic and Cultural Flourishing", "Aftermath of a Natural Cataclysm", "Period of Religious Reformation", "Time of Political Upheaval and Reform", "Age Dominated by a Powerful Dynasty", "Era of Exploration and Cultural Exchange", "Industrial Advancement and Social Change", "Age of Scientific Breakthroughs", "Environmental Awakening and Preservation Efforts", "Spiritual Renaissance and Philosophical Inquiry", "Period of Isolation and Cultural Stagnation", "Aftermath of a Widespread Epidemic", "Era of Trade Expansion and Mercantilism", "Time of Feudal Conflict and Knightly Orders", "Age of Myth and Legend Building", "Period of Colonial Rule and Resistance", "Renaissance of Magic and Mysticism", "Age of Pirates and Maritime Adventure", "Time of Anarchic Freedom and Lawlessness", "Period of Enlightenment and Rational Thought", "Era of Monstrous Threats and Heroic Tales", "Post-War Reconstruction and Healing", "Golden Age of Peace and Diplomacy", "Dark Age of Suppression and Tyranny", "Age of Discovery and Invention"]
    },
    {name: 'Alternate Dimensions', value: '', order: 0, simple:false, heading: 'Basic', custom: false, 
      dropDownOptions: ["None", "Heaven", "Hell", "Limbo", "Purgatory", "The Warp", "The Astral Plane", "The Ethereal Plane", "The Elemental Plane of Fire", "The Elemental Plane of Water", "The Elemental Plane of Earth", "The Elemental Plane of Air", "The Shadow Realm", "The Feywild", "The Underworld", "The Dreamlands", "The Void", "The Celestial Spheres", "The Infernal Depths", "The Plane of Time", "The Plane of Magic", "The Realm of Chaos", "The Realm of Order", "The Dimension of Light", "The Dimension of Darkness", "The Plane of Spirits", "The Plane of Consciousness", "The Mirror Dimension", "The Temporal Rift", "The Nexus of Realities", "The Land of Legends"]
    },
    {name: 'Dominant Species and Cultures', value: '', order: 0, simple:true, heading: 'Inhabitants and Societies', custom: false, 
      dropDownOptions: ["Humans, Culturally Diverse", "Elves, Artistically Inclined", "Dwarves, Craftsmanship-Oriented", "Orcs, Honor-Focused", "Merfolk, Sea Tradition-Valuing", "Giants, Storytelling Tradition", "Centaurs, Nature-Bonded", "Goblins, Ingenious Tinkerers", "Fairies, Mystically Attuned", "Dragons, Wisdom-Valuing Solitaries", "Vampires, Ancient Customs", "Werewolves, Pack Loyalty-Based", "Undead, Ancestral Worship", "Angels, Harmony-Seeking", "Demons, Chaos-Embracing", "Robots, Logic-Driven", "Aliens, Exploration-Focused", "Minotaurs, Labyrinthine Culture", "Trolls, Superstition-Based Beliefs", "Elementals, Elemental Balance", "Shapeshifters, Adaptability-Valuing", "Sphinxes, Riddle and Lore", "Harpies, Freedom-Valuing", "Golems, Duty and Service", "Satyrs, Revelry and Festivity", "Phoenixes, Cycle of Renewal", "Griffins, Nobility and Honor", "Nymphs, Nature and Fertility", "Zombies, Survival Instinct", "Cyborgs, Augmentation and Enhancement"]
    },
    {name: 'Main Societal Structures and Governments', value: '', order: 0, simple:true, heading: 'Inhabitants and Societies', custom: false, 
      dropDownOptions: ["Feudal Kingdom", "Tribal Confederacy", "Democratic Republic", "Theocratic Hierarchy", "Military Junta", "Nomadic Council", "Oligarchic Council", "Technocratic Assembly", "Anarchic Communes", "Totalitarian Regime", "Aristocratic Society", "Parliamentary Democracy", "Matriarchal Community", "Patriarchal Clan System", "Socialist Federation", "Communist Collective", "Plutocratic Elite", "Meritocratic Institutions", "Direct Democracy Villages", "Federalist States", "City-State Alliance", "Hunter-Gatherer Bands", "Colonial Dominion", "Corporate Conglomerate", "Fascist Dictatorship", "Guild-Based Republic", "Magocracy (Magic Users' Rule)", "Syndicalist Unions", "Egalitarian Cooperative", "Caste System Society"]
    },
    {name: 'Predominant Belief Systems and Religions', value: '', order: 0, simple:true, heading: 'Inhabitants and Societies', custom: false, 
      dropDownOptions: ["Polytheism with a Pantheon of Gods", "Monotheism with a Single Deity", "Animism, Revering Natural Spirits", "Ancestor Worship and Veneration", "Dualism, Belief in Two Opposing Forces", "Shamanism, Spirit Communication", "Atheism, No Deity Belief", "Agnosticism, Uncertainty about Deities", "Pantheism, Identifying the Divine in Nature", "Totemism, Sacred Animal Spirits", "Paganism, Ancient Nature Rituals", "Syncretism, Blending Multiple Religions", "Mysticism, Pursuit of Spiritual Union", "Humanism, Focus on Human Values", "Deism, Belief in a Non-Interventionist Deity", "Secularism, Separation of Religion from State", "Cult of the Ancestors, Forebear Reverence", "Elemental Worship, Honoring Natural Elements", "Cosmic Religion, Reverence for the Universe", "Theocracy, Rule by Religious Leaders", "Druidism, Nature and Earth Mysticism", "Asceticism, Pursuit of Spiritual Enlightenment", "Esotericism, Hidden Knowledge and Truths", "Mythology-Based Belief System", "Solar Worship, Reverence of the Sun", "Lunar Worship, Reverence of the Moon", "Star Worship, Celestial Bodies as Divine", "Ritual Magic Practices", "Philosophical Beliefs, Ethical Living Focus", "Apocalyptic Beliefs, Focus on End Times"]
    },
    {name: 'Languages and Communication Methods', value: '', order: 0, simple:false, heading: 'Inhabitants and Societies', custom: false, 
      dropDownOptions: ["Common Speech", "Pictographic Writing", "Sign Language", "Hieroglyphic Script", "Tonal Language", "Whistled Language", "Symbolic Language", "Body Language", "Morse Code", "Telepathic Communication", "Harmonic Language (using musical tones)", "Runic Script", "Braille", "Gesture-Based Communication", "Smoke Signals", "Drum Language", "Binary Code (for digital communication)", "Color-Based Signaling", "Scent-Based Communication", "Birdsong Mimicry", "Light-Based Signaling (e.g., lighthouses)", "Semaphore (flag signaling)", "Morse-Like Tapping Code", "Hand Signals (used in military or covert operations)", "Lip Reading", "Coded Messages (using ciphers)", "Animal Messenger System", "Mythical Language (spoken only in legends)", "Elemental Language (communicating through elements like wind or fire)", "Abstract Conceptual Language (beyond words and symbols)"]
    },
    {name: 'Notable Figures and Leaders', value: '', order: 0, simple:false, heading: 'Inhabitants and Societies', custom: false, 
      dropDownOptions: ["Benevolent King/Queen", "Ruthless Warlord", "Wise Archmage", "Charismatic Rebel Leader", "Legendary Explorer", "Visionary Inventor", "Revered High Priest/Priestess", "Feared Assassin Guildmaster", "Influential Merchant Prince", "Fabled Warrior Hero", "Cunning Spy Master", "Renowned Bard and Storyteller", "Master Alchemist", "Powerful Sorcerer/Sorceress", "Influential Diplomat", "Tyrannical Emperor/Empress", "Mysterious Oracle", "Revolutionary Scientist", "Skilled Gladiator Champion", "Legendary Rogue Trader", "Noble Knight Commander", "Renegade Pirate Captain", "Esteemed Philosopher", "Savage Tribal Chieftain", "Master Artisan", "Charitable Philanthropist", "Ruthless Crime Lord", "Fearless Explorer", "Unyielding Freedom Fighter", "Trailblazing Astronaut"]
    },
    {name: 'Level and Types of Technology', value: '', order: 0, simple:true, heading: 'Technology and Magic', custom: false, 
      dropDownOptions: ["Stone Tools and Basic Agriculture", "Bronze Working and Early Metallurgy", "Iron Forging and Complex Metalwork", "Water Wheels and Basic Hydraulics", "Windmills and Early Mechanical Devices", "Gunpowder and Early Firearms", "Printing Press and Renaissance Inventions", "Steam Engines and Early Industrial Machinery", "Telegraph and Basic Electrical Systems", "Combustion Engines and Automobiles", "Early Flight Technology (e.g., biplanes)", "Radio and Early Telecommunications", "Mid-20th Century Electronics (e.g., television)", "Early Computer Technology", "Nuclear Power and Basic Atomic Science", "Space Exploration and Satellite Technology", "Early Robotics and Automated Systems", "Personal Computing and Internet", "Mobile Technology and Wireless Communications", "Renewable Energy (solar, wind, hydro)", "Genetic Modification and Biotechnology", "Advanced Robotics and AI Integration", "Nanotechnology and Microengineering", "Virtual Reality and Advanced Simulation", "Advanced Medical Technologies (e.g., regenerative medicine)", "Quantum Computing and Theoretical Physics", "Fusion Energy Research and Development", "Advanced Space Travel (e.g., Mars missions)", "Cybernetics and Human Augmentation", "Environmental Engineering and Eco-Technologies"]
    },
    {name: 'Magical Systems and Mystical Powers', value: '', order: 0, simple:false, heading: 'Technology and Magic', custom: false, 
      dropDownOptions: ["None", "Elemental Magic", "Necromancy", "Illusionism", "Enchantment", "Divination", "Summoning", "Alchemy", "Shamanism", "Runecasting", "Telekinesis", "Telepathy", "Healing Magic", "Curse Magic", "Time Magic", "Astral Projection", "Transmutation", "Warding and Protection Magic", "Elemental Control", "Ritual Magic", "Shadow Magic", "Light Magic", "Blood Magic", "Chaos Magic", "Weather Magic", "Planar Magic", "Dream Magic", "Gravity Magic", "Nature Magic", "Mystic Arts", "Arcane Knowledge"]
    },
    {name: 'Predominant Methods of Travel and Transportation', value: '', order: 0, simple:false, heading: 'Technology and Magic', custom: false, 
      dropDownOptions: ["Animal Riding", "Sail-Powered Vessels", "Animal-Drawn Carriages", "Walking", "Canoes and Rowboats", "Hot Air Balloons", "Gliders", "Steam-Powered Trains", "Stagecoaches", "Bicycles", "Early Model Automobiles", "Airships and Zeppelins", "Motorcycles", "Trams and Streetcars", "Subterranean Rail Systems", "Commercial Airliners", "High-Speed Rail Networks", "Helicopters", "Hovercraft", "Magical Teleportation", "Flying Enchanted Objects", "Riding Mythical Creatures", "Spacecraft", "Personal Flying Devices", "Submersible Vehicles", "Magical Portals", "Rapid Transit Tubes", "Interstellar Vessels", "Sleds Pulled by Animals", "Snowshoes and Skis"]
    },
    {name: 'Innovations and Inventions', value: '', order: 0, simple:false, heading: 'Technology and Magic', custom: false, 
      dropDownOptions: ["Discovery of Fire and Controlled Burning", "Invention of the Wheel", "Development of Agriculture (Crop Cultivation)", "Creation of Written Language", "Building of Aqueducts for Water Supply", "Development of Sailing Ships for Exploration", "Invention of the Printing Press", "Gunpowder and its Military Uses", "Steam Engine and Industrial Machinery", "Electrical Power Generation and Distribution", "Telegraph for Long-Distance Communication", "Invention of the Automobile", "Flight with Airplanes", "Radio Communication", "Penicillin and Antibiotics", "Nuclear Energy and its Applications", "Personal Computers and the Internet", "Mobile Phones and Wireless Communication", "Satellite Technology for Global Connectivity", "Discovery of Magic or Mystical Energy Source", "Creation of a Portal to Another Dimension", "Alchemical Transmutation (Turning Lead into Gold)", "Magical Healing Techniques", "Development of a Universal Language", "Invention of a Time-Keeping Device", "Architectural Marvels (e.g., Pyramids, Castles)", "Creation of a Legendary Weapon or Artifact", "Discovery of a New Continent or Land", "Invention of a New Art or Music Form", "Formation of a United Government or Alliance"]
    },
    {name: 'Resource Utilization and Management', value: '', order: 0, simple:false, heading: 'Technology and Magic', custom: false, 
      dropDownOptions: ["Large-Scale Industrial Farming", "Extensive Deforestation for Timber", "Reliance on Renewable Energy Sources", "Overreliance on Single Resource", "Advanced Water Conservation Techniques", "Uncontrolled Urban Expansion", "Comprehensive Recycling Programs", "Exploitative Mining Practices", "Sustainable Wildlife Management", "Excessive Industrial Pollution", "Efficient Public Transportation Systems", "Inadequate Waste Management", "Controlled Magical Resource Use", "Destructive Fishing Practices", "Investment in Green Technologies", "Neglected Land and Soil Management", "Preservation of Cultural Heritage Sites", "Overuse of Chemicals in Agriculture", "Wasteful Consumer Practices", "Effective Disaster Management and Planning", "Space Resource Exploration without Regulation", "Maintenance of Biodiversity and Ecosystems", "Lack of Environmental Regulations", "High-Efficiency Manufacturing Processes", "Poor Air Quality Control", "Development of Sustainable Communities", "Mismanagement of Cross-Dimensional Resources", "Exploitation of Magical Creatures", "Adoption of Eco-Friendly Building Practices", "Short-Sighted Resource Planning"]
    },
    {name: 'Major Conflicts and Alliances', value: '', order: 0, simple:false, heading: 'Dynamics and Current State', custom: false, 
      dropDownOptions: ["Territorial War between Nations", "Rebellion against a Tyrannical Regime", "Alliance of City-States against a Common Enemy", "Religious Conflict between Sects", "Cold War and Espionage", "Trade Wars and Economic Sanctions", "Revolutionary Movement for Democracy", "Sectarian Violence and Civil Unrest", "Coalition to Combat a Global Threat", "Magical Faction Rivalries", "Struggle for Independence from Colonial Power", "Feudal Lords Vying for Control", "Pirates Versus Naval Forces", "Technological Arms Race", "Interplanetary Conflict between Alien Races", "Guilds in Conflict over Resources", "Power Struggle within a Royal Family", "Peasant Uprising against Aristocracy", "Environmental Activists versus Industrialists", "Alliance to Preserve Magical Balance", "Secret Societies Competing for Influence", "Race for Control of a Powerful Artifact", "Conflict between Humanoid Species", "War between Immortal Beings", "Crusade against a Mythical Evil", "Political Factions Fighting for Supremacy", "Resistance Movement against Occupying Forces", "Tension between Traditionalists and Reformers", "Struggle against a Pandemic or Natural Disaster", "Alliance for Scientific and Technological Advancement"]
    },
    {name: 'Current Events and Crises', value: '', order: 0, simple:true, heading: 'Dynamics and Current State', custom: false, 
      dropDownOptions: ["Global Pandemic Outbreak", "Widespread Natural Disasters", "Imminent Threat of War", "Political Coup and Regime Change", "Economic Recession or Depression", "Major Technological Breakthrough", "Discovery of a New Continent or Planet", "Rise of a Powerful Religious Cult", "Sudden Climate Change Impact", "Large-Scale Protests and Civil Unrest", "Breakthrough in Magical Research", "Escalation of a Border Conflict", "Environmental Catastrophe", "Eruption of Long-Dormant Volcano", "Mysterious Disappearance of Key Figure", "Alien Contact or Invasion", "Outbreak of a Magical Plague", "Revelation of Hidden or Forbidden Knowledge", "Assassination of a Prominent Leader", "Collapse of a Major Empire or Kingdom", "Emergence of a New Species or Race", "Drastic Shift in Power Dynamics", "Major Archaeological Discovery", "Technological Malfunction or Crisis", "Threat of an Ancient Prophecy Unfolding", "Surge in Criminal or Terrorist Activities", "Widespread Famine or Drought", "Significant Astronomical Event", "Exposure of a Major Conspiracy", "Invention Causing Societal Upheaval"]
    },
    {name: 'Unique Laws and Phenomena', value: '', order: 0, simple:true, heading: 'Dynamics and Current State', custom: false, 
      dropDownOptions: ["Gravity Fluctuations in Certain Areas", "Magical Fields that Nullify Technology", "Time Distortions in Specific Regions", "Laws Prohibiting the Use of Magic", "Seasonal Weather Inversions", "Bioluminescent Forests at Night", "Rituals Required for Major Life Events", "Portals Connecting Distant Locations", "Animal Species that Can Communicate Verbally", "Night Sky with Two Moons", "Reversal of Aging in Sacred Sites", "Plants that Grow Instantly", "Compulsory Participation in Annual Festivals", "Forbidden Ancient Languages", "Cities Floating in the Sky", "Sacred Grounds Where Fighting is Prohibited", "Rivers that Flow Uphill", "Mandatory Dueling to Settle Disputes", "Continuous Daylight or Nighttime for Months", "Invisibility Zones", "Creatures Visible Only to Certain People", "Strict Hierarchies Based on Birth Elements", "Periodic Appearances of Ghosts or Spirits", "Cursed Territories Bringing Misfortune", "Laws Mandating Hospitality to Strangers", "Dimensional Overlaps Causing Illusions", "Prohibition of Certain Forms of Art", "Natural Phenomena that Influence Magic", "Regions Where Dreams Manifest Physically", "Places Where Silence is Enforced by Law"]
    },
    {name: 'Recent Discoveries or Changes', value: '', order: 0, simple:false, heading: 'Dynamics and Current State', custom: false, 
      dropDownOptions: ["Discovery of a Hidden Underwater City", "Sudden Change in Global Climate Patterns", "Unearthing of an Ancient Magical Text", "Rediscovery of a Long-Lost Species", "Shift in the Earth's Magnetic Poles", "Reappearance of a Mythical Creature", "Sudden Emergence of a New Island", "Discovery of Caverns Filled with Rare Crystals", "Unexplained Alteration in Animal Behavior", "Discovery of Ruins from an Unknown Civilization", "Mysterious Disappearance of a Major River", "Uncovering of a Vast Underground Network", "Sighting of a New Astronomical Object", "Rapid Advancement of a Plant or Animal Species", "Dramatic Increase in Seismic Activity", "Discovery of an Ancient Prophecy Coming True", "Unforeseen Shift in Political Alliances", "Sudden Change in the Population's General Health", "Discovery of New Natural Resources", "Unexpected Phenomenon in the Night Sky", "Discovery of an Alternate Dimensional Plane", "Unusual Weather Patterns Emerging Globally", "Significant Alteration in Social Customs", "Emergence of a New Religion or Belief System", "Rapid Erosion Revealing Unknown Structures", "Sudden Collapse of a Historical Landmark", "Unexpected Migration of a Major Species", "Discovery of a New Form of Energy", "Sudden Transformation of a Landscape", "Appearance of Unexplainable Artifacts"]
    },
    {name: 'Significant Locations and Landmarks', value: '', order: 0, simple:false, heading: 'Dynamics and Current State', custom: false, 
      dropDownOptions: ["The Enchanted Forest of Eldara", "The Citadel of Eternal Light", "The Ruined City of Azrath", "The Floating Isles of Aetheria", "The Obsidian Mountains", "The Lost Library of Archanon", "The Whispering Caves", "The Sacred Tree of Serenity", "The Crystal Labyrinth of Zephyr", "The Eternal Abyss", "The Dragon's Roost", "The Veil of Shadows", "The Grand Bazaar of Crossroads", "The Isle of Forgotten Dreams", "The Ruins of Atlantis", "The Clockwork City of Mechanar", "The Tower of Arcane Knowledge", "The Forbidden Abyssal Trench", "The Celestial Observatory", "The Elemental Nexus", "The Gardens of Eternal Blossoms", "The Spire of Ascendance", "The Mystic Pools of Tranquility", "The Lost Mines of Moragor", "The Singing Mountains", "The Palace of the Moon Empress", "The Abyssal Chasm of Despair", "The Luminous Caverns", "The Isle of Whispers", "The Ruins of the Elders"]
    },
    {name: 'Ongoing Mysteries and Quests', value: '', order: 0, simple:false, heading: 'Dynamics and Current State', custom: false, 
      dropDownOptions: ["The Search for the Lost City of Elara", "Investigating the Vanishing Ships of the Tides", "Unraveling the Riddle of the Whispering Stones", "The Quest for the Fabled Sword of Valor", "Solving the Mystery of the Cursed Amulet", "Tracking Down the Legendary White Stag", "The Hunt for the Elusive Phantom Thief", "Exploring the Enigma of the Shadowed Realm", "Quest to Decode the Ancient Runes of Eldren", "Delving into the Secrets of the Forbidden Library", "Pursuing the Mysterious Disappearances in the Woods", "The Search for the Missing Heir of the Kingdom", "Uncovering the Truth Behind the Eclipsed Sun", "Investigating the Haunting of Raven's Manor", "The Quest for the Hidden Treasure of Captain Blackbeard", "Solving the Puzzle of the Enchanted Maze", "Tracking the Origins of the Howling Winds", "The Hunt for the Elemental Crystals", "Exploring the Lost Catacombs of Darkness", "Quest to Retrieve the Stolen Relics of the Ancients", "Delving into the Legends of the Starborn Prophets", "Pursuing the Mysterious Cult of the Crimson Moon", "The Search for the Mysterious Elixir of Immortality", "Unraveling the Enigma of the Ghost Ship", "Investigating the Curse of the Weeping Willow", "The Quest to Ascend the Tower of Trials", "Solving the Riddle of the Shifting Sands", "Tracking Down the Illusive Masked Vigilante", "The Hunt for the Whispering Tunnels of the Underworld", "Exploring the Secrets of the Time-Traveling Portal"]
    },
    {name: 'Additional Details', value: '', order: 0, heading: 'Background', custom: false},
    {name: 'Setting (user generated)', value: '', order: 0, simple:true, heading: 'Background', custom: false, dropDownOptions: [], specialDropdown: true, specialDropdownType: 'Narrative', displayOutsideAccordian:true, attachedObject: {}},
    {name: 'Within Location (user generated)', value: '', order: 0, simple:true, heading: 'Background', custom: false, dropDownOptions: [], specialDropdown: true, specialDropdownType: 'Location', displayOutsideAccordian:true, attachedObject: {}},
  ];
