import React, { useState, useEffect } from 'react';

const RadioButtonForm = ({ formLayouts, currentMode, onUpdate, onSubmit, toggleLayout, singleColumn, toggleSimpleMode, simpleMode }) => {
  const [selectedBase, setSelectedBase] = React.useState(Object.keys(currentMode)[0]);
  const [selectedOption, setSelectedOption] = React.useState(Object.values(currentMode)[0]);

  const handleBaseChange = (event) => {
    setSelectedBase(event.target.value);
    if(Object.keys(currentMode)[0] == event.target.value)
        setSelectedOption(Object.values(currentMode)[0]);
    else
        setSelectedOption(formLayouts[event.target.value][0]);
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    onUpdate(JSON.parse('{"' + selectedBase + '" : "' + event.target.value + '"}'));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(JSON.parse('{"' + selectedBase + '" : "' + selectedOption + '"}'));
  };
  console.log(simpleMode);
  return (
    <div>
    <h4>Composer Settings</h4>
        <form className="radioButtons" onSubmit={handleSubmit}>

          <div className="simpleModeTickbox" >
            <label>
                <input type="checkbox" checked={simpleMode}  onChange={toggleSimpleMode} />
                Simple Mode
            </label>
            <p>Simple mode is quicker with less inputs and smaller descriptions but is less creative.</p>
          </div>

          <div className="simpleModeTickbox" >
            <label>
                <input type="checkbox" checked={singleColumn}  onChange={toggleLayout} />
                Single Column
            </label>
            <p>Puts input fields each on their own line instead of double column.</p>
          </div>

          <div className="saveBtnContainer">
            <button type="submit">Save</button>
        </div>
        </form>
    </div>
  );
};

export default RadioButtonForm;
