import React from 'react';
import { FaDiscord,FaCheckCircle, FaPlus } from 'react-icons/fa';

import ScrollToTopButton from '../Composer/ScrollToTopButton.js';

var Signup = ({ showLoginModal, toggleLoginModal }) => {
  return (
    <div className="sign-up" id="sign-up">
      <div className="sign-up-form-container section">
        
        
        <div className="upsell-modal-content">
        <h2>Sign Up</h2>
        <p>Create a <span>free</span> account</p>
            <ul>
              <li><FaCheckCircle/><span>Higher</span> limits on creations</li>
              <li><FaCheckCircle/><span>Save &amp; Edit</span> your creations</li>
              <li><FaCheckCircle/><span>Like</span> other creations</li>
            </ul>
              <div className="upsell-modal-actions">

            </div>
          </div>
          <button id="checkout-and-portal-button" onClick={toggleLoginModal}>
          Create Account
        </button>
      </div>
      <ScrollToTopButton />
    </div>
  );
};

export default Signup;
