import React from 'react';
import { FaRedoAlt } from 'react-icons/fa';
import Spinner from './Spinner';

// Define the CharacterParagraph component
const CharacterParagraph = ({title, children, handleClick, isActive}) => {
  let content;

  if (title === "Memorable Quote" || title === "Battle Cry" || title === "Taunts and Threats") {
    content = <div className="characterSpeech">"{children}"</div>;
  } else {
    content = children;
  }

  return (
    <div className={`character-paragraph ${isActive ? 'active' : ''}`}>
      <h3>{title}</h3>
      <p>{content}</p>
      <FaRedoAlt className="description-refresh"  onClick={() => handleClick(title)} />
      {isActive &&
      <div id="CharacterParagraphLoadingSpinner"><Spinner /></div>
      }
    </div>
  );
}

export default CharacterParagraph;
