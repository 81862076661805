import React, { useState, useEffect, useRef } from 'react';

const ImageComponent = ({ imageUrl, exspanded }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLargeImage, setIsLargeImage] = useState(false); // New state to track image size
  const imageRef = useRef(null);

  useEffect(() => {
      const checkImageSize = () => {
        const imageElement = imageRef.current;
        if (imageElement && imageElement.naturalWidth > 300) {
          setIsLargeImage(true); // Update state if image is large
          if(exspanded){
            imageElement.style.cursor = 'pointer';
            imageElement.onclick = () => setIsModalOpen(true);
          }
        } else {
          setIsLargeImage(false); // Update state if image is not large
          imageElement.style.cursor = 'auto';
          imageElement.onclick = () => {};
        }
      };

      const intervalId = setInterval(checkImageSize, 500);
      return () => clearInterval(intervalId); // Clear interval on component unmount

  }, [exspanded, imageUrl]);

  return (
    <div>
      <div className="image-container">
        <img ref={imageRef} className={`portrait ${isLargeImage ? 'large-image' : ''}`} src={imageUrl} alt="Portrait" />
        {isLargeImage && <div className="image-label">Dalle 3</div>}
      </div>

      {isModalOpen && (
        <div style={modalStyle}>
          <div style={overlayStyle} onClick={() => setIsModalOpen(false)} />
          <img src={imageUrl} style={fullSizeImageStyle} alt="Full Size" />
        </div>
      )}
    </div>
  );
};

const modalStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1000,
};

const overlayStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.6)',
};

const fullSizeImageStyle = {
  zIndex: 1001,
  maxWidth: '90%',
  maxHeight: '90%',
  margin: 'auto',
  border: '1px solid black',
  borderRadius:'2px'
};


export default ImageComponent;
