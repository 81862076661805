import React, { useState, useEffect } from 'react';
import ReactSelect from 'react-select';

const SelectSection = ({  label, name, value, type,mode, changeParentValue }) => {

  const [selectedValue, setSelectedValue] = useState(value || '');
  const [recentCreations, setRecentCreations] = useState([]);
  const validRecentCreations = Array.isArray(recentCreations) ? recentCreations : [];
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setSelectedValue(value || ''); 
  }, [value]);

  function getBaseURL() {
      var url = window.location.href;
      var apiUrl = 'http://localhost:3001/saved/images/';
        if(url.indexOf('charactercomposer.com') > -1)
           apiUrl = 'https://charactercomposer.com/saved/images/';
      return apiUrl;
    }
  let selectOptions = validRecentCreations
      .filter(creation => creation.formValues && creation.formValues.Name)
      .map(creation => ({
          value: creation._id,  // Use _id as the unique value
          label: creation.formValues.Name,
          type: type == 'Location'? creation.mode.Location : creation.mode.Narrative,
          imageUrl: getBaseURL() + creation.imageUrl
  }));

  selectOptions.unshift({ value: 'own', label: 'Choose ' + (mode || type) + '...' });
  const ddHeight = selectedValue && selectedValue !== 'None' ? 60 : 24;
  const opacity = selectedValue && selectedValue !== 'None' ? 1 : 0.6;
  const customStyles = {
    option: (provided) => ({
      ...provided,
      padding: '6px 3px',
      textAlign: 'right',
      color: 'black',
      fontSize: '16px',
      whiteSpace: 'nowrap',
      overflow:'hidden',
    }),
    control: (provided) => ({
      ...provided,
      padding: '0px 0px',
      height:ddHeight,
      minHeight:ddHeight,
      linHeight : '24px',
      background:'#ddd',
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '0 0px',   // Adjust the vertical padding here
      height: ddHeight,
      textAlign:'left',
      opacity: opacity
      
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: ddHeight,
      
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: '0px',  // Adjust the padding to vertically center the dropdown arrow
      color:'black'
    }),
    clearIndicator: (provided) => ({
      ...provided,
      padding: '0px',  // Adjust the padding for the clear icon as well
    }),
  };

  // Custom option rendering to include the image
  const CustomOption = React.forwardRef(({ data, innerProps, getStyles, ...props }, ref) => (
    <div 
        {...innerProps} 
        ref={ref} 
        style={{
            ...getStyles('option', props),
            ...innerProps.style, 
            display: 'flex', 
            alignItems: 'center'
        }}
    >
        {data.imageUrl && <img src={data.imageUrl} alt={data.label} style={{ width: '30px', marginRight: '10px' }} />}
        {data.label}&nbsp;<div className={`ddType ${data?.type?.replace(/\s+/g, '-')}`}>{data.type}</div>
    </div>
  ));

  const CustomSingleValue = React.forwardRef(({ data, innerProps, getStyles, ...props }, ref) => (
    <div 
        {...innerProps} 
        ref={ref} 
        style={{
            ...getStyles('singleValue', props),
            display: 'flex',
            alignItems: 'center',
        }}
    >
        {data.imageUrl && <img src={data.imageUrl} alt={data.label} style={{ width: '30px', marginRight: '10px' }} />}
        {data.label}&nbsp;<div className={`ddType ${data?.type?.replace(/\s+/g, '-')}`}>{data.type}</div>
    </div>
));

      
  const defaultOption = selectOptions.find(option => option.value === 'own');

  const handleGetRecentCreations = async () => {
      // You should create a URL object from the window.location.href string
      var url = new URL(window.location.href);
      var apiUrl = 'http://localhost:3001/characters_endpoint/';
      
      if (url.hostname === 'charactercomposer.com') {
        apiUrl = 'https://charactercomposer.com/characters_endpoint/';
      }

      const requestBody = {
        limit: 20,
        page: 1,
        orderBy: 'recent',
        modeKey: type,
        mode: mode
      };

      const res = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'API-Key': "2(yY62.mG#6UI324324"
        },
        body: JSON.stringify(requestBody),
      });

      const result = await res.json();
      return result;
  };

const handleGetMyCreations = async () => {
  var url = new URL(window.location.href);
  var apiUrl = 'http://localhost:3001/get_my_characters/';
  
  if (url.hostname === 'charactercomposer.com') {
    apiUrl = 'https://charactercomposer.com/get_my_characters/';
  }
  const requestBody = {
      limit: 100,
      page: 1,
      modeKey: type,
      mode:mode
    };
  const res = await fetch(apiUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'API-Key': "2(yY62.mG#6UI324324",
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
    },
    body: JSON.stringify(requestBody),
  });

  const result = await res.json();
  // Check if the response contains a characters array and return it
  if (result && Array.isArray(result)){
    return result;
  }
};

  const handleSelectChange = (selectedOption) => {
    if (selectedOption.value === 'own') {
        setSelectedValue('None');
        changeParentValue(label, 'None');
    } else {
        const matchingCreation = validRecentCreations.find(creation => creation._id === selectedOption.value);
        changeParentValue(label, selectedOption.value, false, 'Basic', matchingCreation);

    }
};



   // Move fetchData outside of useEffect
  const fetchData = async () => {
    setIsLoading(true);
    const creations = await handleGetRecentCreations();
    let myCreations = await handleGetMyCreations();
    myCreations = Array.isArray(myCreations) ? myCreations : [];

    if (creations && Array.isArray(creations)) {
      const combinedCreations = [...myCreations, ...creations];
      const uniqueCreations = combinedCreations.filter(
        (creation, index, self) => index === self.findIndex((c) => c._id === creation._id)
      );
      setRecentCreations(uniqueCreations);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    console.log('selectsection use effect');
    fetchData();

    const interval = setInterval(() => {
      fetchData();
    }, 30000); // Refresh every 30 seconds

    return () => clearInterval(interval); // Cleanup on unmount
  }, [recentCreations.length]);

    return (
      <div className='form-section select-section'>
        <div>
          <label className="form-label" htmlFor={name}>
            {label}
          </label>
          <ReactSelect 
              className="SelectSection"
              options={selectOptions}
              components={{ Option: CustomOption, SingleValue: CustomSingleValue }}
              onChange={handleSelectChange}
              defaultValue={defaultOption}
              styles={customStyles}
              value={selectOptions.find(option => option.value === value) || defaultOption}
          />
        </div>
      </div>
    );
};

export default SelectSection;
