import React from 'react';
import RecentCreations from '../Sections/RecentCreations.js';

const DnDLanding = ({}) => {
  return (
    <>
    <div className="landingpage dnd">
      <div className="container">
          <div className="row">
            <div>
              <h1>Embark on Your Greatest Adventure with the Ultimate DnD Character Creator!</h1>
              <img src={`${process.env.PUBLIC_URL}/sales/8CkypYadlFCML2BoKBPctTbQ3D.png`}/>
              <p>Unleash Your Imagination</p>
              <p>Welcome to the World of Endless Possibilities</p>
              <p>Discover the easiest and most intuitive way to create your dream Dungeons and Dragons character. Our DnD Character Creator is designed to guide both new and experienced players through a seamless and enjoyable character creation process.</p>
            </div>
            <div>
              <img src={`${process.env.PUBLIC_URL}/sales/unnamed (6).png`}/>
            </div>
          </div>
          <div className="row">
            <div>
              <h2>Features</h2>
              <ul>
                <li><strong>Comprehensive Customization:</strong> Dive into an extensive range of options that allows you to tailor every aspect of your character. From race, class, and background to unique skills and appearance, our DnD Character Creator brings your character to life.</li>
                <br/><li><strong>User-Friendly Interface:</strong> Whether you're a seasoned player or just starting out, our tool ensures a smooth experience with its simple and clear layout.</li>
                <br/><li><strong>Quality Image Generation:</strong> Watch your character take form as you make choices. Our image generation will craft a unique image that matches your character’s appearance, skills and traits.</li>
              </ul>
            </div>
            <div>
              <img src={`${process.env.PUBLIC_URL}/sales/retVtnhaRv6YF8JPTOdiApH03D.png`}/>
            </div>
          </div>
          <div className="row">
            <div>
              <h2>Why Choose Our DnD Character Creator?</h2>
              <p><strong>Intuitive Design:</strong>  Easy to navigate for users of all experience levels.</p>
              <p><strong>In-Depth Options:</strong>  Detailed customization to satisfy even the most imaginative players.</p>
              <p><strong>Save & Share:</strong>  Easily save your creation and share it with your gaming group.</p>
              <p><strong>Expert Tips:</strong>  Get suggestions and guidance at every step to enhance your character.</p>
              <br/><a href="/"><button className="form-button">Create Character Now!</button></a><br/><br/>
              <div className="describe-character-box">
                <p>Join thousands of players who have created their ultimate DnD characters with us.</p>
              </div>
            </div>
            <div>
              <img src={`${process.env.PUBLIC_URL}/sales/unnamed (1).png`}/> <img src={`${process.env.PUBLIC_URL}/sales/unnamed (3).png`}/>
            </div>
          </div>
          <div className="row">
            <div>
              <p>Check out the latest and greatest characters created by the community using our DnD character creator!</p>
            </div>
            <div>
              
            </div>
          </div>
          
      </div>
    </div>
    <div className="container">
    <RecentCreations mode={'recent'}/>
    </div>
    </>
  );
};

export default DnDLanding;
