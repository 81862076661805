import React from 'react';

const TagsDisplay = ({ tags, onEdit = false, showAddTag, onAddTag, onClickHandler, activeTags = false }) => {
  const handleOnClick = (e, tag, index) => {
    e.preventDefault();
    e.stopPropagation();
    if (onEdit === false) {
      onClickHandler(tag);
    } else {
      onEdit(index);
    }
  };

  // Function to check if a tag is active
  const isActive = (tag) => {
    return activeTags && activeTags.some(activeTag => activeTag.name === tag.name);
  };

  return (
    <div className={showAddTag ? "TagsContainer slider" : "TagsContainer"}>
      <div className={showAddTag ? "tagsSlider" : ""}>
      {showAddTag && (
          <button className="addNew" onClick={onAddTag}>Add Tags</button>
        )}
        {tags.map((tag, index) => (
          <button
            key={index}
            onClick={(e) => handleOnClick(e, tag, index)}
            className={isActive(tag) ? 'active' : ''}
          >
            {tag.name}
          </button>
        ))}
      </div>
    </div>
  );
};

export default TagsDisplay;
